<template>
  <v-card class="rounded-xl mx-1 my-1">
    <h4 style="color: #004643; background-color: #abd1c6" class="py-2 px-8">
      Aset Terdampak {{ idx + 1 }}
    </h4>
    <div class="px-8 py-2">
      <h5 v-if="asset.category">Kategori Utama</h5>
      <p class="body-2 mt-1">{{ asset.category }}</p>
      <h5 v-if="asset.sub_category">Sub Kategori</h5>
      <p class="body-2 mt-1">{{ asset.sub_category }}</p>
      <h5 v-if="asset.advance_category">Kategori Lanjutan</h5>
      <p class="body-2 mt-1">{{ asset.advance_category }}</p>
      <h5 v-if="asset.condition">Deskripsi Kondisi Aset</h5>
      <p class="body-2 mt-1">{{ asset.condition }}</p>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["asset", "idx"],
};
</script>

<style></style>
