<template>
  <v-container class="py-5" style="max-width: 90vw">
    <h1 style="font-size: 1.5rem; font-weight: 700">Gangguan Aset</h1>
    <v-breadcrumbs :items="crumbs" class="px-0 pb-0"></v-breadcrumbs>

    <chart-component
      title="Total Gangguan Aset"
      class="mb-8"
      url-chart="/chart-gangguan-aset"
      :parentFunc="updateDate"
    />

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10">
      Laporan Gangguan Aset
    </h1>

    <v-layout justify-end class="my-3">
      <v-card-title class="ma-0 py-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-card-title>
      <v-btn
        class="white--text text-capitalize"
        color="#004643"
        width="150"
        height="34"
        @click="openDialogDownload"
        >Download</v-btn
      >
    </v-layout>

    <main-table-component :headers="headers" :items="computedTableItems" />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogDownload" width="auto">
      <v-card class="px-5 py-5">
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('XLSX')"
        >Download as XLSX</v-btn
        >
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('PDF')"
        >Download as PDF</v-btn
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ChartComponent from "@/components/ChartComponent";
import MainTableComponent from "@/components/MainTableComponent.vue";

export default {
  components: {
    ChartComponent,
    MainTableComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      const response = await this.$axios.get(
        `/all-asset-disruptions?from=${this.dateFrom}&to=${this.dateTo}`
      );
      this.tableItems = response.data.data.asset_disruptions;
    },
    async downloadAs(format) {
      const queryParams = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      try {
        const endpoint = format === "XLSX" ? "/download-asset-disruptions" : "/download-asset-disruptions/as-pdf";
        const response = await this.$axios.get(endpoint, {
          params: queryParams,
        });
        const mediaType = format === "XLSX" ? "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," : "data:application/pdf;base64,";
        const base64Data = format === "XLSX" ? response.data.excelData : response.data.pdfData;

        const url = mediaType + base64Data;
        const ext = format === "XLSX" ? "xlsx" : "pdf";

        const a = document.createElement("a");
        const formattedDate1 = `${this.dateFrom.slice(
          9,
          11
        )}${this.dateFrom.slice(5, 7)}${this.dateFrom.slice(0, 4)}`;
        const formattedDate2 = `${this.dateTo.slice(9, 11)}${this.dateTo.slice(
          5,
          7
        )}${this.dateTo.slice(0, 4)}`;
        const fileName = `Gangguan Aset_${formattedDate1}_${formattedDate2}.${ext}`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    openDialogDownload() {
      this.dialogDownload = true;
    }
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((asset_disruption) => ({
        ...asset_disruption,
        asset_damaged: asset_disruption.asset_damaged
          ? "Merusak"
          : "Tidak Merusak",
        compensation: asset_disruption.compensation
          ? "Ganti Rugi"
          : "Tidak Ada Ganti Rugi",
        href: `/lalu-lintas/gangguan-aset/${asset_disruption.id}`,
      }));
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      dateFrom: null,
      dateTo: null,
      headers: [
        {
          text: "Waktu Kejadian",
          align: "start",
          value: "incident_time",
        },
        { text: "Lokasi Kejadian", value: "incident_location" },
        { text: "Tipe Kejadian", value: "type" },
        { text: "Uraian Penanganan", value: "handling_description" },
        { text: "Dampak Aset", value: "asset_damaged" },
        { text: "Kerugian", value: "compensation" },
        {
          text: "Foto",
          value: "image_urls",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
      crumbs: [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Gangguan Aset",
          disabled: true,
          href: "/lalu-lintas/gangguan-aset",
        },
      ],
      search: "",
      dialogDownload: false,
    };
  },
};
</script>

<style></style>
