<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="6" height="100%">
        <card-photo-group-component :images="assetDisruption?.image_urls" />
        <v-card class="mt-5 px-8 py-8 rounded-xl">
          <h5>Waktu Kejadian</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.incident_time }}</p>
          <h5>Shift</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.shift }}</p>
          <h5>Lokasi Kejadian</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.incident_location }}</p>
          <h5>Tipe Kejadian</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.type }}</p>
          <h5>Uraian Kejadian</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.incident_description }}</p>
          <h5>Uraian Penanganan</h5>
          <p class="body-2 mt-1">{{ assetDisruption?.handling_description }}</p>
        </v-card>
      </v-col>
      <v-col cols="6" height="100%">
        <v-card class="px-8 py-8 rounded-xl" height="100%">
          <h5>Merusak Aset</h5>
          <p class="body-2 mt-1">
            {{ assetDisruption?.asset_damaged ? "Merusak" : "Tidak Merusak" }}
          </p>
          <h5>Kerugian</h5>
          <p class="body-2 mt-1">
            {{
              assetDisruption?.compensation
                ? "Ganti Rugi"
                : "Tidak Ada Ganti Rugi"
            }}
          </p>
          <template v-if="assetDisruption?.asset_damaged">
            <h5>Merusak Aset</h5>
            <v-carousel class="body-2 mt-1" v-model="model" height="none">
              <v-carousel-item
                v-for="(asset, idx) in assetDisruption.damaged_assets"
                :key="idx"
                class="px-8"
              >
                <v-card class="rounded-xl mx-1 my-1">
                  <h4
                    style="color: #004643; background-color: #abd1c6"
                    class="py-2 px-8"
                  >
                    Aset Terdampak {{ idx + 1 }}
                  </h4>
                  <div class="px-8 py-2">
                    <h5 v-if="asset.category">Kategori Utama</h5>
                    <p class="body-2 mt-1">{{ asset.category }}</p>
                    <h5 v-if="asset.sub_category">Sub Kategori</h5>
                    <p class="body-2 mt-1">{{ asset.sub_category }}</p>
                    <h5 v-if="asset.advance_category">Kategori Lanjutan</h5>
                    <p class="body-2 mt-1">{{ asset.advance_category }}</p>
                    <h5 v-if="asset.condition">Deskripsi Kondisi Aset</h5>
                    <p class="body-2 mt-1">{{ asset.condition }}</p>
                  </div>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";

export default {
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.$axios.get(
        `/asset-disruption/${this?.$route?.params.id}`
      );
      this.assetDisruption = response.data.data;
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(
          `/asset-disruption/${this?.$route?.params.id}`
        );
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/gangguan-aset");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    async download() {
      try {
        const response = await this.$axios.get(
          `/download-asset-disruption/${this?.$route?.params?.id}`
        );
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Detail Gangguan Aset ${this?.$route?.params?.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CardPhotoGroupComponent,
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    idData: "",
    images: [],
    model: 0,
    assetDisruption: null,
  }),
  computed: {
    title() {
      return `Detail Gangguan Aset (${this?.$route?.params?.id})`;
    },
    crumbs() {
      return [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Gangguan Aset",
          disabled: false,
          href: "/lalu-lintas/gangguan-aset",
        },
        {
          text: `Detail Gangguan Aset (${this?.$route?.params?.id})`,
          disabled: true,
          href: `/lalu-lintas/gangguan-aset/${this?.$route?.params.id}`,
        },
      ];
    },
  },
};
</script>

<style></style>
