<template>
  <div>
    <date-filter-component
      :date-from="date1"
      :date-to="date2"
      :func="recallData"
    />
    <div
      class="my-2 px-3 rounded-xl"
      style="box-shadow: 0px 2px 10px 0px #e8e4e67d"
    >
      <v-layout row wrap justify-space-between align-center class="pa-5">
        <h2 class="font-weight-bold" style="font-size: 1rem; font-weight: 700">
          {{ title }}
        </h2>
        <v-col cols="2" class="pa-0">
          <v-select
            :items="[
              { label: 'Harian', type: 'day' },
              { label: 'Mingguan', type: 'week' },
              { label: 'Bulanan', type: 'month' },
            ]"
            v-model="type"
            item-text="label"
            item-value="type"
            dense
            solo
            height="10"
            hide-details
            @input="recallData"
          ></v-select>
        </v-col>
      </v-layout>

      <div id="chart">
        <apexchart
          type="line"
          height="350"
          ref="realtimeChart"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DateFilterComponent from "@/components/DateFilterComponent.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    DateFilterComponent,
  },
  beforeMount() {
    this.init();
    if (typeof this.parentFunc === "function") {
      this.parentFunc(this.date1, this.date2);
    }
  },
  methods: {
    async updateChartData(chartType, chartOptions, chartData, apiEndpoint) {
      const response = await this.$axios.post(apiEndpoint, {
        type: this.type,
        date_start: this.date1,
        date_end: this.date2,
      });

      chartOptions.xaxis.categories = response.data.categories;
      chartData[0].data = response.data["x-data"];

      if (this.$refs[chartType]) {
        this.$refs[chartType].updateSeries(chartData);
        this.$refs[chartType].updateOptions(chartOptions);
      }
    },
    async init() {
      await this.updateChartData(
        "realtimeChart",
        this.chartOptions,
        this.series,
        this.urlChart
      );
    },
    async recallData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date1 = dateFrom;
        this.date2 = dateTo;
        if (typeof this.parentFunc === "function") {
          this.parentFunc(dateFrom, dateTo);
        }
      }

      await this.updateChartData(
        "realtimeChart",
        this.chartOptions,
        this.series,
        this.urlChart
      );
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      date1: initialDate.date_start,
      date2: initialDate.date_end,
      type: "month",
      series: [
        {
          name: "Jumlah",
          data: [],
        },
      ],
      chartOptions: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  props: {
    title: String,
    urlChart: String,
    dateStart: String,
    dateEnd: String,
    parentFunc: Function,
  },
};
</script>

<style></style>
