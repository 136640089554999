<template>
  <v-row class="my-2">
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="6">
      <h2 style="font-size: 1.5rem; font-weight: 500">{{ title }}</h2>
      <h2
          v-if="title === 'Pendapatan'"
          style="font-size: 2.25rem; font-weight: 700; color: #004643"
      >
        Rp{{ (data.grand_total || 0).toLocaleString("id-ID") }},-
      </h2>
      <h2
          v-if="title === 'Kecepatan Transaksi'"
          style="font-size: 2.25rem; font-weight: 700; color: #004643"
      >
        {{ data.average_time || 0 }} Detik (Rata-Rata)
      </h2>
      <h2
          v-if="title === 'Jumlah Antrian'"
          style="font-size: 2.25rem; font-weight: 700; color: #004643"
      >
        {{ data.most_queue.type_range || 0 }} (Paling Banyak)
      </h2>
      <template
          v-if="
          title === 'Pendapatan' || title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'
        "
      >
        <h2
            v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'"
            style="font-size: 2.25rem; font-weight: 700; color: #004643"
        >
          {{ (data.grand_total || 0).toLocaleString("id-ID") }} Kendaraan
        </h2>
        <v-row class="mt-2 mb-8">
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan 1</h3>
              <h3 v-if="title === 'Pendapatan'">
                Rp{{ (data.total_class_1 || 0).toLocaleString("id-ID") }},-
              </h3>
              <h3 v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'">
                {{ (data.total_class_1 || 0).toLocaleString("id-ID") }} Kendaraan
              </h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan 2</h3>
              <h3 v-if="title === 'Pendapatan'">
                Rp{{ (data.total_class_2 || 0).toLocaleString("id-ID") }},-
              </h3>
              <h3 v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'">
                {{ (data.total_class_2 || 0).toLocaleString("id-ID") }} Kendaraan
              </h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan 3</h3>
              <h3 v-if="title === 'Pendapatan'">
                Rp{{ (data.total_class_3 || 0).toLocaleString("id-ID") }},-
              </h3>
              <h3 v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'">
                {{ (data.total_class_3 || 0).toLocaleString("id-ID") }} Kendaraan
              </h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan 4</h3>
              <h3 v-if="title === 'Pendapatan'">
                Rp{{ (data.total_class_4 || 0).toLocaleString("id-ID") }},-
              </h3>
              <h3 v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'">
                {{ (data.total_class_4 || 0).toLocaleString("id-ID") }} Kendaraan
              </h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan 5</h3>
              <h3 v-if="title === 'Pendapatan'">
                Rp{{ (data.total_class_5 || 0).toLocaleString("id-ID") }},-
              </h3>
              <h3 v-if="title === 'Volume Lalu Lintas Harian' || title === 'Volume Lalu Lintas Harian Tertimbang'">
                {{ (data.total_class_5 || 0).toLocaleString("id-ID") }} Kendaraan
              </h3>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-if="title === 'Kecepatan Transaksi'">
        <v-row class="mt-2 mb-8">
          <v-col cols="2">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Waktu T1</h3>
              <h3>{{ data.t1 || 0 }} Detik</h3>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Waktu T2</h3>
              <h3>{{ data.t2 || 0 }} Detik</h3>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Waktu T3</h3>
              <h3>{{ data.t3 || 0 }} Detik</h3>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Waktu T4</h3>
              <h3>{{ data.t4 || 0 }} Detik</h3>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Waktu T5</h3>
              <h3>{{ data.t5 || 0 }} Detik</h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Jumlah Input</h3>
              <h3>{{ data.inputs_number || 0 }} Kali</h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Golongan Kendaraan</h3>
              <h3>
                Golongan {{ data.max_vehicle_class_id }} ({{
                  data.max_vehicle_class_value
                }}x)
              </h3>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Jenis E-Toll Terbanyak</h3>
              <h3>{{ data.etoll }} ({{ data.max_etoll_value }}%)</h3>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-if="title === 'Jumlah Antrian'">
        <v-row class="mt-2 mb-8">
          <v-col cols="8">
            <PhotoGroupCarouselComponent
                :images="images"
            ></PhotoGroupCarouselComponent>
          </v-col>
          <v-col cols="4">
            <v-card
                class="d-flex flex-column align-start"
                style="
                padding: 8px 16px;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Background-Secondary, #f9bc60);
              "
            >
              <h3>Jumlah Input</h3>
              <h3>{{ data.queues.length || 0 }} Kali</h3>
            </v-card>
          </v-col>
        </v-row>
      </template
      >

      <v-btn
          class="white--text text-capitalize"
          color="#004643"
          @click="download"
      >Download Laporan
      </v-btn
      >
    </v-col>
    <v-col cols="6">
      <div
          class="my-2 px-3 rounded-xl"
          style="box-shadow: 0px 2px 10px 0px #e8e4e67d; height: 100%"
      >
        <v-layout row wrap justify-space-between align-center class="pa-5">
          <h2
              class="font-weight-bold"
              style="font-size: 1rem; font-weight: 700"
          >
            {{ title }}
          </h2>
          <template v-if="title != 'Jumlah Antrian'">
            <v-col cols="3" class="pa-0 ml-auto mr-3">
              <v-select
                  :items="
                  title === 'Kecepatan Transaksi'
                    ? [{ label: 'Waktu Rata-Rata', type: '' }]
                    : [
                        { label: 'Seluruh Golongan', type: '' },
                        { label: 'Golongan 1', type: 'class_1' },
                        { label: 'Golongan 2', type: 'class_2' },
                        { label: 'Golongan 3', type: 'class_3' },
                        { label: 'Golongan 4', type: 'class_4' },
                        { label: 'Golongan 5', type: 'class_5' },
                      ]
                "
                  v-model="class_type"
                  item-text="label"
                  item-value="type"
                  dense
                  solo
                  height="10"
                  hide-details
                  @input="
                  updateChartData(
                    'realtimeChart',
                    chartOptions,
                    series,
                    urlChart
                  )
                "
              ></v-select>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-select
                  :items="[
                  { label: 'Harian', type: 'day' },
                  { label: 'Mingguan', type: 'week' },
                  { label: 'Bulanan', type: 'month' },
                ]"
                  v-model="date_type"
                  item-text="label"
                  item-value="type"
                  dense
                  solo
                  height="10"
                  hide-details
                  @input="
                  updateChartData(
                    'realtimeChart',
                    chartOptions,
                    series,
                    urlChart
                  )
                "
              ></v-select>
            </v-col>
          </template>
        </v-layout>

        <div id="chart" v-if="title != 'Jumlah Antrian'">
          <apexchart
              type="line"
              height="350"
              ref="realtimeChart"
              :options="chartOptions"
              :series="series"
          ></apexchart>
        </div>
        <div
            v-if="title == 'Jumlah Antrian'"
            style="height: 80%"
            class="d-flex align-center justify-center"
        >
          <main-table-component
              :headers="tableHeaders"
              :items="computedQueue"
              :hideFooter="true"
              style="width: 90%"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import PhotoGroupCarouselComponent from "./PhotoGroupCarouselComponent.vue";
import MainTableComponent from "@/components/MainTableComponent.vue";

export default {
  components: {
    apexchart: VueApexCharts,
    PhotoGroupCarouselComponent,
    MainTableComponent,
  },
  computed: {
    images() {
      if (Array.isArray(this.data.queues)) {
        return this.data.queues.map((d) => d.image_url);
      }
      return [];
    },
    computedQueue() {
      const typeRanges = ["0-5 Kendaraan", "5-10 Kendaraan", ">10 Kendaraan"];
      const counts = this.data.queues.reduce((acc, queue) => {
        acc[queue.type_range] = (acc[queue.type_range] || 0) + 1;
        return acc;
      }, {});

      const mappedData = typeRanges.map((type) => ({
        type,
        count: counts[type] || 0,
      }));
      return mappedData;
    },
  },
  beforeMount() {
    this.init();
  },
  watch: {
    dateStart: "init",
    dateEnd: "init",
  },
  methods: {
    async init() {
      await this.getData();
      await this.updateChartData(
          "realtimeChart",
          this.chartOptions,
          this.series,
          this.urlChart
      );
    },
    async getData() {
      const response = await this.$axios.get(this.statUrl, {
        params: {
          type: "Month",
          from: this.dateStart,
          to: this.dateEnd,
        },
      });
      this.data = response.data.data[0] || response.data.data;
    },
    async updateChartData(chartType, chartOptions, chartData, apiEndpoint) {
      if (!this.urlChart) {
        return;
      }
      const response = await this.$axios.post(apiEndpoint, {
        type: this.date_type,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        class: this.class_type,
      });

      chartOptions.xaxis.categories = response.data.categories;
      chartData[0].data = response.data["x-data"];

      if (this.$refs[chartType]) {
        this.$refs[chartType].updateSeries(chartData);
        this.$refs[chartType].updateOptions(chartOptions);
      }
    },
    async download() {
      const queryParams = {
        from: this.dateStart,
        to: this.dateEnd,
      };

      try {
        const response = await this.$axios.get(this.downloadUrl, {
          params: queryParams,
        });
        const mediaType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        a.href = url;
        a.download = `${this.downloadName}.xlsx`;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          text: "Jumlah Antrian",
          align: "start",
          value: "type",
          sortable: false,
        },
        {
          text: "Jumlah Laporan",
          align: "start",
          value: "count",
          sortable: false,
        },
      ],
      snackbar: false,
      message: "",
      timeout: 3000,
      data: {},
      class_type: "",
      date_type: "month",

      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: '20%',
          },
        },
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis:{
          labels: {
            formatter: function (value) {
              return value.toLocaleString("id-ID");
            }
          },
        }
      },
      series: [
        {
          name: "Jumlah",
          data: [],
        },
      ],
    };
  },
  props: [
    "dateStart",
    "dateEnd",
    "title",
    "statUrl",
    "downloadUrl",
    "downloadName",
    "urlChart",
  ],
};
</script>

<style></style>
