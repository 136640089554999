<template>
  <div
    style="border-radius: 20px; border: 1px #abd1c6 solid; height: 100%"
    class="px-5 py-5 align-center justify-center"
  >
    <div>
      <h1 style="font-size: 18px; font-weight: 700; text-align: center">
        Foto
      </h1>
    </div>
    <v-carousel class="mt-3" height="200" style="border-radius: 15px">
      <v-carousel-item
        v-for="image in images"
        :key="image"
        :src="image"
        cover
        @click="openDialog(image)"
      ></v-carousel-item>
    </v-carousel>
    <v-dialog v-model="dialog" max-width="80vw">
      <v-card>
        <v-img :src="imageUrl" max-height="80vh" contain />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["images"],
  methods: {
    openDialog(image) {
      this.imageUrl = image;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
