<template>
  <v-card class="rounded-xl mx-1 my-1">
    <h4 style="color: #004643; background-color: #abd1c6" class="py-2 px-8">
      Kendaraan {{ idx + 1 }}
    </h4>
    <div class="px-8 py-2">
      <h5 v-if="vehicle.vehicle_type">Jenis Kendaraan</h5>
      <p class="body-2 mt-1">{{ vehicle.vehicle_type }}</p>
      <h5 v-if="vehicle.vehicle_class">Golongan</h5>
      <p class="body-2 mt-1">{{ vehicle.vehicle_class }}</p>
      <h5 v-if="vehicle.police_number">Nomor Polisi</h5>
      <p class="body-2 mt-1">{{ vehicle.police_number }}</p>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["vehicle", "idx"],
};
</script>

<style></style>
