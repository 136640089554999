<template>
  <div style="border-radius: 20px; border: 1px #abd1c6 solid" class="px-7 py-7">
    <div>
      <h1 style="font-size: 16px; font-weight: 700">Foto</h1>
    </div>
    <v-container class="mt-3">
      <v-row>
        <v-col
          v-for="image in images"
          :key="image"
          style="display: flex"
          class="align-center justify-center"
        >
          <img
            :src="image"
            style="border-radius: 15px"
            :height="200"
            @click="openDialog(image)"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="80vw">
      <v-card>
        <v-img :src="imageUrl" max-height="80vh" contain />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["images"],
  methods: {
    openDialog(image) {
      this.imageUrl = image;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
