<template>
  <div
    style="
      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
      border-radius: 15px;
    "
  >
    <div
      style="
        background: #bee3d9;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      "
      class="d-flex align-center"
    >
      <h3
        style="font-size: 16px; font-weight: 700; word-wrap: break-word"
        class="px-3 py-2"
      >
        {{ label }}
      </h3>
      <a
        class="ml-auto mr-2"
        @click.stop="dialog = true"
        v-if="isCustom && editable"
      >
        <v-icon>mdi-close</v-icon>
      </a>
      <v-dialog v-model="dialog" width="434">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <p>
              Apakah Anda yakin ingin menghapus informasi ini? Tindakan ini
              tidak dapat dibatalkan.
            </p></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                dialog = false;
                $emit('closeCard');
              "
            >
              Ya
            </v-btn>
            <v-btn color="red" text @click="dialog = false"> Tidak </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-text-field
        v-if="editable"
        class="px-3 py-2"
        style="font-size: 14px; font-weight: 400; word-wrap: break-word"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue"
      ></v-text-field>
      <h5
        v-else
        class="px-3 py-2"
        style="font-size: 14px; font-weight: 400; word-wrap: break-word"
      >
        {{ value }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number],
    editable: Boolean,
    isCustom: Boolean,
    placeholder: String,
  },
  methods: {
    updateValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
