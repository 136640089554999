<template>
  <div
    class="mb-3"
    style="
      background: #fffffe;
      border-radius: 15px;
      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
    "
  >
    <div
      style="border-bottom: 1px #f9bc60 solid; display: flex"
      class="py-2 px-4 align-center justify-space-between"
    >
      <h4>Kecelakaan ({{ id }})</h4>
      <a :href="`/lalu-lintas/kecelakaan/${id}`" style="height: 24px">
        <img
          :src="require('../assets/icons/chevron-right.svg')"
          style="cursor: pointer"
        />
      </a>
    </div>
    <div
      style="display: flex"
      class="align-center justify-space-between px-4 py-4"
    >
      <div>
        <h5>Waktu Kejadian</h5>
        <h5>{{ waktuKejadian }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["waktuKejadian", "id"],
};
</script>

<style></style>
