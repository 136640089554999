<template>
  <div>
    <h1
      v-if="tableTitle"
      style="font-size: 1.5rem; font-weight: 500"
      class="mt-10"
    >
      {{ tableTitle }}
    </h1>

    <v-layout justify-end class="my-3">
      <v-card-title class="ma-0 py-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-card-title>
      <v-btn
        v-if="tableTitle"
        class="white--text text-capitalize"
        color="#004643"
        width="150"
        height="34"
        @click="downloadFunc"
        >Download</v-btn
      >
    </v-layout>

    <main-table-component
      :headers="headers"
      :items="items"
      :slot-name="slotName"
      :table-title="tableTitle"
      :search="search"
      :enable-download="enableDownload"
      :file-name="fileName"
    ></main-table-component>
  </div>
</template>

<script>
import MainTableComponent from "@/components/MainTableComponent.vue";
export default {
  components: {
    MainTableComponent,
  },
  props: {
    slotName: String,
    tableTitle: String,
    headers: Array,
    items: Array,
    downloadFunc: Function,
    enableDownload: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: "data.xlsx"
    }
  },
  data: () => ({
    search: "",
  }),
};
</script>

<style></style>
