<template>
  <div
    class="mb-3"
    style="
      background: #fffffe;
      border-radius: 15px;
      box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
    "
  >
    <div
      style="border-bottom: 1px #f9bc60 solid; display: flex"
      class="py-2 px-4 align-center justify-space-between"
    >
      {{ penyebab }}
      <a :href="computedHref" style="height: 24px" v-if="source">
        <img
          :src="require('../assets/icons/chevron-right.svg')"
          style="cursor: pointer"
        />
      </a>
      <a v-if="penyebab.startsWith('Diubah')" @click="handlePopup">
        <img
            :src="require('../assets/icons/chevron-right.svg')"
            style="cursor: pointer"
        />
      </a>
    </div>
    <div
      style="display: flex"
      class="align-center justify-space-between px-4 py-4"
    >
      <div>
        <h5>Waktu Kejadian</h5>
        <h5>{{ waktuKejadian }}</h5>
      </div>
      <div>
        <h5>Waktu Selesai</h5>
        <h5>
          {{ source ? (waktuSelesai ? waktuSelesai : "-") : waktuKejadian }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handlePopup() {
      this.openPopup(this.historyId, this.historyEditor);
    }
  },
  props: ["penyebab", "waktuKejadian", "waktuSelesai", "source", "id", "historyId", "historyEditor", "openPopup"],
  computed: {
    computedHref() {
      if (
        this.source?.toLowerCase() === "default" ||
        this.source?.toLowerCase() === "inspeksi"
      ) {
        return `/layanan-pemeliharaan/inisiasi/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "accident" ||
        this.source?.toLowerCase() === "kecelakaan"
      ) {
        return `/lalu-lintas/kecelakaan/${this.id}`;
      } else if (
        this.source?.toLowerCase() === "asset_disruption" ||
        this.source?.toLowerCase() === "gangguan aset"
      ) {
        return `/lalu-lintas/gangguan-aset/${this.id}`;
      } else {
        return "#";
      }
    },
  },
};
</script>

<style></style>
