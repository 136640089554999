import { render, staticRenderFns } from "./ListKendaraanAmbulansPage.vue?vue&type=template&id=5271cecd&scoped=true"
import script from "./ListKendaraanAmbulansPage.vue?vue&type=script&lang=js"
export * from "./ListKendaraanAmbulansPage.vue?vue&type=script&lang=js"
import style0 from "./ListKendaraanAmbulansPage.vue?vue&type=style&index=0&id=5271cecd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5271cecd",
  null
  
)

export default component.exports