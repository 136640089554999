<template>
  <div
    class="card-container px-8 py-8"
    :class="{
      'with-bg': assetCategory,
    }"
    style="height: 100%"
  >
    <div v-if="assetCategory !== undefined || assetCategory != null">
      <h6 class="mb-2">Kategori Utama</h6>
      <p>{{ assetCategory }}</p>
    </div>
    <div v-if="assetSubCategory !== undefined || assetSubCategory != null">
      <h6 class="mb-2">Sub Kategori</h6>
      <p>{{ assetSubCategory }}</p>
    </div>
    <div
      v-if="assetAdvanceCategory !== undefined || assetAdvanceCategory != null"
    >
      <h6 class="mb-2">Kategori Lanjutan</h6>
      <p>{{ assetAdvanceCategory }}</p>
    </div>
    <div
      style="display: flex"
      class="align-center mt-5"
      v-if="location !== undefined || location != null"
    >
      <img
        :src="require('../assets/icons/mdi_location.svg')"
        style="height: 32px; width: 32px"
      />
      <h6>{{ location }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "assetCategory",
    "assetSubCategory",
    "assetAdvanceCategory",
    "location",
  ],
};
</script>

<style scoped>
.card-container {
  box-shadow: 0px 2px 6px #e8e4e6;
  border-radius: 20px;
}

.with-bg {
  background-image: url("@/assets/illustration_y_3.svg");
  background-position: right bottom;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 20px;
  font-weight: 500;
  word-wrap: break-word;
}
</style>
