<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center">
      <div>
        <title-icon-component
          title="Riwayat Aset Tak Berwujud"
          image="aset_tak_berwujud.svg"
        />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          @click="download"
          class="white--text text-capitalize"
          color="#004643"
          >Download</v-btn
        >
      </div>
    </div>
    <date-filter-component
      :date-from="date_start"
      :date-to="date_end"
      :func="recallData"
    />

    <table-component
      class="mt-5"
      :headers="tableHeaders"
      :items="computedTableItems"
      :slotName="`item.waktuResponse`"
    />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import TitleIconComponent from "@/components/TitleIconComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DateFilterComponent from "@/components/DateFilterComponent";
export default {
  components: {
    TableComponent,
    TitleIconComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.$axios.get(
        `/asset-histories-by-asset-category?category=5&from=${this.date_start}&to=${this.date_end}`
      );
      this.tableItems = response.data.data.histories;
      console.log(this.tableItems);
    },
    async download() {
      const queryParams = {
        from: this.date_start,
        to: this.date_end,
        category: "5",
      };

      try {
        const response = await this.$axios.get(
          "/download-asset-histories-by-asset-category",
          {
            params: queryParams,
          }
        );
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        const formattedDate1 = `${this.date_start.slice(
          9,
          11
        )}${this.date_start.slice(5, 7)}${this.date_start.slice(0, 4)}`;
        const formattedDate2 = `${this.date_end.slice(
          9,
          11
        )}${this.date_end.slice(5, 7)}${this.date_end.slice(0, 4)}`;
        const fileName = `Riwayat Aset TaK Berwujud_${formattedDate1}_${formattedDate2}.xlsx`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    getInitialDate() {
      const currentDate = new Date();

      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const formattedCurrentDate = formatDate(currentDate);
      const formattedStartDate = formatDate(thirtyDaysAgo);

      return {
        date_start: formattedStartDate,
        date_end: formattedCurrentDate,
      };
    },
    async recallData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date_start = dateFrom;
        this.date_end = dateTo;
        const response = await this.$axios.get(
          `/asset-histories-by-asset-category?category=5&from=${this.date_start}&to=${this.date_end}`
        );
        this.tableItems = response.data.data.histories;
        console.log(this.tableItems);
      }
    },
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((item) => {
        const modifiedItem = { ...item };
        if (modifiedItem["source"] === null) {
          modifiedItem["source"] = modifiedItem.asset_status;
          modifiedItem["current_state"] = modifiedItem.asset_status;
          modifiedItem["report_time"] = modifiedItem.execution_date;
          modifiedItem["finish_time"] = modifiedItem.execution_date;
        }
        Object.keys(modifiedItem).forEach((key) => {
          if (modifiedItem[key] === null) {
            modifiedItem[key] = "-";
          }
        });
        let href;
        if (
          modifiedItem?.source?.toLowerCase() === "default" ||
          modifiedItem?.source?.toLowerCase() === "inspeksi"
        ) {
          href = `/layanan-pemeliharaan/inisiasi/${modifiedItem.initial_report_id}`;
        } else if (
          modifiedItem?.source?.toLowerCase() === "accident" ||
          modifiedItem?.source?.toLowerCase() === "kecelakaan"
        ) {
          href = `/lalu-lintas/kecelakaan/${modifiedItem.accident_id}`;
        } else if (
          modifiedItem?.source?.toLowerCase() === "asset_disruption" ||
          modifiedItem?.source?.toLowerCase() === "gangguan aset"
        ) {
          href = `/lalu-lintas/gangguan-aset/${modifiedItem.asset_disruption_id}`;
        } else {
          href = "#";
        }

        return {
          ...modifiedItem,
          href: href,
        };
      });
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      date_start: initialDate.date_start,
      date_end: initialDate.date_end,
      crumbs: [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "Aset Tak Berwujud",
          disabled: false,
          href: "/aset/aset-tak-berwujud",
        },
        {
          text: "Riwayat Aset Tak Berwujud",
          disabled: true,
          href: "/aset/aset-tak-berwujud/riwayat",
        },
      ],
      tableHeaders: [
        {
          text: "Fase Saat Ini",
          align: "start",
          value: "current_state",
        },
        { text: "Penyebab", value: "source" },
        { text: "Nama Aset", value: "asset_name" },
        { text: "Lokasi Aset", value: "asset_location" },
        { text: "Inspektur/Petugas", value: "reporter" },
        { text: "Waktu Laporan", value: "report_time" },
        { text: "Waktu Penyelesaian", value: "finish_time" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
    };
  },
};
</script>

<style></style>
