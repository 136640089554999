<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div class="mb-7">
      <title-component :title="title"/>
      <breadcrumbs-component :crumbs="crumbs"/>
    </div>

    <v-form ref="form" @submit.prevent="submitForm" v-model="valid">
      <v-row>
        <v-col cols="6" v-for="i in 5" :key="i">
          <h4>Golongan {{ i }}</h4>
          <v-text-field
              v-model="price_data[`class_${i}`]"
              :rules="rules"
              :placeholder="`Golongan ${i}`"
              required
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="text-center mt-auto">
        <v-btn
            class="text-capitalize mx-1"
            color="#004643"
            height="34"
            width="150"
            outlined
            @click="resetForm"
        >Batal
        </v-btn
        >

        <v-btn
            class="white--text text-capitalize mx-1"
            height="34"
            width="150"
            color="#004643"
            @click="submitForm"
        >Simpan
        </v-btn
        >
      </div>
    </v-form>

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10 my-3">
      Riwayat Tarif
    </h1>

    <main-table-component
        :headers="headers"
        :items="items"
    ></main-table-component>

    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import MainTableComponent from "@/components/MainTableComponent.vue";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    MainTableComponent,
  },
  beforeMount() {
    this.init();
  },
  computed: {
    validForm() {
      return this.$refs.form ? this.$refs.form.validate() : false;
    },
  },
  methods: {
    async init() {
      this.items = await this.$axios
          .get("/tariffs")
          .then((res) => res.data.data);
    },
    async submitForm() {
      if (this.validForm) {
        try {
          await this.$axios.post("/tariff", this.price_data);
          this.message = "Berhasil menyimpan data";
          this.snackbar = true;
          this.$refs.form.reset();
          this.init();
        } catch (error) {
          this.message = "Gagal menyimpan data";
          this.snackbar = true;
          console.error(error);
        }
      } else {
        console.log("Form validation failed");
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
  },
  data: () => ({
    snackbar: false,
    timeout: 3000,
    message: "",
    items: [],
    headers: [
      {text: "Tanggal", sortable: true, value: "created_at"},
      ...Array.from({length: 5}, (_, i) => ({
        text: `Golongan ${i + 1}`,
        sortable: false,
        value: `class_${i + 1}`,
      })),
    ],
    valid: false,
    rules: [
      (v) => !!v || "Data is required",
      (v) => /^\d+$/.test(v) || "Harga harus berupa angka",
    ],
    price_data: Object.fromEntries(
        Array.from({length: 5}, (_, i) => [`class_${i + 1}`, null])
    ),
    name: "",
    email: "",
    title: "Pengaturan Tarif",
    crumbs: [
      {
        text: "Layanan Transaksi",
        disabled: false,
        href: "/layanan-transaksi",
      },
      {
        text: "Atur Tarif",
        disabled: true,
        href: "/layanan-pemeliharaan/atur-tarif",
      },
    ],
  }),
};
</script>

<style></style>
