<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <p class="my-5">
      Berisikan daftar aset yang terkena dampak insiden yang harus di
      kategorisasi.
    </p>
    <v-row>
      <v-col cols="6">
        <card-image-component
          title="Jumlah Aset Rusak"
          :number="totalDamaged"
          imageName="broken-asset.svg"
        />
      </v-col>
      <v-col cols="6">
        <card-image-component
          title="Jumlah Aset Diperbaiki"
          :number="totalFixed"
          imageName="fixing-asset.svg"
        />
      </v-col>
    </v-row>

    <date-filter-component
      :date-from="date1"
      :date-to="date2"
      :func="getData"
    />

    <table-component :headers="tableHeaders" :items="computedInitialReports" />
  </v-container>
</template>

<script>
import * as constant from "@/constants/constant";
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DateFilterComponent from "@/components/DateFilterComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import CardImageComponent from "@/components/CardImageComponent.vue";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
    TableComponent,
    CardImageComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      await this.getData();
    },
    async getData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date1 = dateFrom;
        this.date2 = dateTo;
      }
      const params = {
        from: this.date1,
        to: this.date2,
        is_recategorized: "false",
        source: "reports",
      };
      const response = await this.$axios.get("/all-initial-reports", {
        params,
      });
      this.reports = response.data.data.initial_reports;
      const dataNumbers = await this.$axios.get(`/stat/asset-report`);
      this.totalDamaged = `${dataNumbers.data.data.number_of_damaged_assets}`;
      this.totalFixed = `${dataNumbers.data.data.number_of_fixed_assets}`;
      this.startCountdown();
    },
    startCountdown() {
      this.reports.forEach((damagedAssetReport) => {
        if (damagedAssetReport.asset_category_id != 3) {
          this.$set(this.countdowns, damagedAssetReport.id, "-");
          return;
        }
        const createdAt = new Date(damagedAssetReport.created_at);
        let deadline;
        if (![6, 7, 8].includes(damagedAssetReport.asset_sub_category_id)) {
          deadline =
            constant.deadlines[damagedAssetReport.asset_sub_category_id] * 3600;
        } else {
          deadline =
            constant.deadlines[damagedAssetReport.asset_advance_category_id] *
            3600;
        }
        setInterval(() => {
          const currentTime = new Date();
          const targetTime = new Date(createdAt.getTime() + deadline * 1000);
          const timeDiff = targetTime - currentTime;

          if (timeDiff < 0) {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000) * -1;
            const seconds = Math.floor((timeDiff % 60000) / 1000) * -1;

            this.$set(
              this.countdowns,
              damagedAssetReport.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          } else {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000);
            const seconds = Math.floor((timeDiff % 60000) / 1000);

            this.$set(
              this.countdowns,
              damagedAssetReport.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          }
        }, 1000);
      });
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
  },
  computed: {
    computedInitialReports() {
      return this.reports.map((damagedAssetReport) => {
        return {
          ...damagedAssetReport,
          asset_category:
            constant.assetCategories[damagedAssetReport.asset_category_id],
          asset_sub_category:
            constant.assetSubCategories[
              damagedAssetReport.asset_sub_category_id
            ] || "-",
          asset_advance_category:
            constant.assetAdvanceCategories[
              damagedAssetReport.asset_advance_category_id
            ] || "-",
          countdown: this.countdowns[damagedAssetReport.id] || "00:00:00",
          href: "/aset/laporan-aset/rekategorisasi/" + damagedAssetReport.id,
        };
      });
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      date1: initialDate.date_start,
      date2: initialDate.date_end,
      reports: [],
      title: "List Laporan Aset",
      tableHeaders: [
        {
          text: "Kategori",
          align: "start",
          value: "asset_category",
        },
        { text: "Sub Kategori", value: "asset_sub_category" },
        {
          text: "Kategori Lanjutan",
          value: "asset_advance_category",
          align: "start",
          sortable: false,
        },
        { text: "Lokasi", value: "location" },
        { text: "Deskripsi Kondisi Aset", value: "condition" },
        { text: "Petugas", value: "reporter" },
        { text: "Waktu Laporan", value: "created_at" },
        { text: "Waktu Penyelesaian", value: "countdown" },
        {
          text: "Foto",
          value: "image_url",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      crumbs: [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "List Laporan Aset",
          disabled: true,
          href: "/aset/laporan-aset",
        },
      ],
      totalDamaged: "0",
      totalFixed: "0",
      countdowns: {},
    };
  },
};
</script>

<style></style>
