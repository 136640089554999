<template>
  <v-app>
    <LayoutPage />
  </v-app>
</template>

<script>
import * as constant from "./constants/constant";
import LayoutPage from "@/layouts/LayoutPage.vue";

export default {
  name: "App",
  components: {
    LayoutPage,
  },
  data: () => ({
    appTitle: constant.APP_TITLE,
  }),
};
</script>
