<template>
  <v-card class="px-2 rounded-xl">
    <v-row>
      <v-col>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <p
            style="
              font-size: 2rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            {{ number }}
          </p>
          <p style="color: #abb5be">
            <span style="color: #e16162">*</span>Dalam 30 hari terakhir
          </p>
        </v-card-text>
      </v-col>
      <v-col cols="4" class="ma-auto">
        <v-img
          max-width="180px"
          max-height="180px"
          :src="require(`@/assets/${imageName}`)"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["title", "number", "imageName"],
  data: () => ({}),
};
</script>

<style></style>
