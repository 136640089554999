<template>
  <v-col
    :class="($style.mainBgColor, $style['background-container2'])"
    style="padding: 0 !important; margin: 0 !important; height: 100vh"
  >
    <h1 :class="$style.greetings">Halo Selamat Datang,</h1>
    <h1 :class="$style['big-name']">{{ account.fullname }}</h1>
    <v-container style="width: 200px; height: 200px; margin: 20px auto">
      <v-img
        style="object-fit: cover; width: 100%; height: 100%; border-radius: 50%"
        :src="
          account.image != null
            ? account.image
            : require('@/assets/default_profile.png')
        "
      ></v-img>
    </v-container>
    <v-container fluid style="width: 80%; margin: 20px auto">
      <v-row>
        <v-col v-for="menu in menus" :key="menu.id" cols="6">
          <NavigationCard
            :icons="menu.icon"
            :title="menu.title"
            :navigation="menu.navigation"
            :disabled="menu.disabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import * as constant from "../constants/constant.js";
import NavigationCard from "@/components/NavigationCard";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: constant.APP_TITLE,
  components: { NavigationCard },
  data: () => ({
    account: null,
    menus: []
  }),
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      if (Cookies.get("loginResponse") == undefined)
        window.location.replace("/login");
      this.account = JSON.parse(Cookies.get("loginResponse"));
      this.menus = [
        {
          id: 1,
          icon: "mdi-account-credit-card",
          title: "Layanan Transaksi",
          disabled: !this.account.scopes.includes(constant.SCOPES.view_transaction),
          navigation: !this.account.scopes.includes(constant.SCOPES.view_transaction) ? "#" : "/layanan-transaksi",
        },
        {
          id: 2,
          icon: "mdi-traffic-light",
          title: "Layanan Lalu Lintas",
          disabled: !this.account.scopes.includes(constant.SCOPES.accident) &&
              !this.account.scopes.includes(constant.SCOPES.asset_disruption) &&
              !this.account.scopes.includes(constant.SCOPES.traffic_disturbance) &&
              !this.account.scopes.includes(constant.SCOPES.special_incident),
          navigation: !this.account.scopes.includes(constant.SCOPES.accident) &&
              !this.account.scopes.includes(constant.SCOPES.asset_disruption) &&
              !this.account.scopes.includes(constant.SCOPES.traffic_disturbance) &&
              !this.account.scopes.includes(constant.SCOPES.special_incident) ? "#" : "/lalu-lintas",
        },
        {
          id: 3,
          icon: "mdi-wrench-clock",
          title: "Layanan Pemeliharaan",
          disabled: !this.account.scopes.includes(constant.SCOPES.maintenance),
          navigation: !this.account.scopes.includes(constant.SCOPES.maintenance) ? "#" : "/layanan-pemeliharaan",
        },
        {
          id: 4,
          icon: "mdi-office-building",
          title: "Layanan Aset",
          disabled: !this.account.scopes.includes(constant.SCOPES.view_asset) &&
              !this.account.scopes.includes(constant.SCOPES.manage_asset) &&
              !this.account.scopes.includes(constant.SCOPES.delete_asset),
          navigation: !this.account.scopes.includes(constant.SCOPES.view_asset) &&
              !this.account.scopes.includes(constant.SCOPES.manage_asset) &&
              !this.account.scopes.includes(constant.SCOPES.delete_asset) ? "#" : "/aset",
        },
      ];
      axios.defaults.headers.common["Authorization"] = this.account.session;
    },
  },
};
</script>

<style lang="scss" module>
/* Import the .module.scss file */
@import "../styles/main.module.scss";
</style>
