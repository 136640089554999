<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    class="elevation-1"
    :items-per-page="20"
    :footer-props="{
      'items-per-page-options': [5, 10, 15, 20],
    }"
    :hide-default-footer="hideFooter"
  >
    <template v-slot:[`item.pilih`]="{ item }">
      <popup-component text="Pilih" v-slot="slotProps">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text> <p>Anda yakin memilih aset ini?</p></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                slotProps.closeDialog();
                submit(item);
              "
            >
              Ya
            </v-btn>
            <v-btn color="red" text @click="slotProps.closeDialog()">
              Tidak
            </v-btn>
          </v-card-actions>
        </v-card>
      </popup-component>
    </template>
  </v-data-table>
</template>

<script>
import PopupComponent from "@/components/PopupComponent.vue";

export default {
  components: {
    PopupComponent,
  },
  methods: {
    submit(item) {
      const payload = {
        assetId: item.id,
        assetStatus: this.source || "Inspeksi",
        initialReportId: this.initialReportId,
      };
      this.$axios.post("/asset-history", payload).then(() => {
        this.$router.push({ name: this.redirect });
      });
    },
    closeDialog(value) {
      this.$emit("close-dialog", value);
    },
  },
  props: {
    slotName: String,
    tableTitle: String,
    headers: Array,
    items: Array,
    search: String,
    hideFooter: {
      type: Boolean,
      default: false,
    },
    initialReportId: [String, Number],
    source: String,
    redirect: String,
  },
};
</script>

<style></style>
