<template>
  <v-container class="py-5" style="max-width: 90vw">
    <h1 style="font-size: 1.5rem; font-weight: 700">Kendaraan Rescue</h1>
    <v-breadcrumbs :items="crumbs" class="px-0 pb-0"></v-breadcrumbs>

    <chart-component
      title="Total Kendaraan Rescue"
      class="mb-8"
      url-chart="/chart-gangguan-aset"
      :parentFunc="updateDate"
    />

    <v-tabs v-model="activeTab" @change="updateDate">
      <v-tab href="#RC.01">RC.01</v-tab>
      <v-tab-item :key="1" value="RC.01" :transition="false">
        <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10 mb-5">
          Laporan Hasil Tugas
        </h1>
        <v-btn-toggle
          v-model="activeTabLaporan"
          mandatory
          style="color: #001e1d"
          @change="updateDate"
        >
          <v-btn style="height: 36px" class="text-capitalize">
            <img src="../assets/icons/clarity_date-line.svg" alt="Left Icon" />
            Per Hari
          </v-btn>
          <v-btn style="height: 36px" class="text-capitalize">
            <img
              src="../assets/icons/material-symbols_list-alt.svg"
              alt="Left Icon"
            />
            Per Laporan
          </v-btn>
        </v-btn-toggle>

        <v-layout justify-end class="my-3">
          <v-card-title class="ma-0 py-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="ma-0 pa-0"
            ></v-text-field>
          </v-card-title>
          <v-btn
            class="white--text text-capitalize"
            color="#004643"
            width="150"
            height="34"
            @click="download"
            >Download</v-btn
          >
        </v-layout>

        <main-table-component
          :headers="headersHarian"
          :items="computedTableItemsHarian"
          v-if="activeTabLaporan == 0"
          :search="search"
        />
        <main-table-component
          :headers="headers"
          :items="computedTableItems"
          v-if="activeTabLaporan == 1"
          :search="search"
        />
      </v-tab-item>

      <v-tab href="#RC.02">RC.02</v-tab>
      <v-tab-item :key="2" value="RC.02" :transition="false">
        <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10 mb-5">
          Cek Kelengkapan
        </h1>
        <v-layout justify-end class="my-3">
          <v-card-title class="ma-0 py-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="ma-0 pa-0"
            ></v-text-field>
          </v-card-title>
          <v-btn
            class="white--text text-capitalize"
            color="#004643"
            width="150"
            height="34"
            >Download</v-btn
          >
        </v-layout>

        <main-table-component
          :headers="headersRc"
          :items="computedTableItemsHarian"
          :search="search"
        />
      </v-tab-item>

      <v-tab href="#RC.03">RC.03</v-tab>
      <v-tab-item :key="3" value="RC.03" :transition="false">
        <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10 mb-5">
          Laporan Uji Fungsi Peralatan
        </h1>
        <v-layout justify-end class="my-3">
          <v-card-title class="ma-0 py-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="ma-0 pa-0"
            ></v-text-field>
          </v-card-title>
          <v-btn
            class="white--text text-capitalize"
            color="#004643"
            width="150"
            height="34"
            >Download</v-btn
          >
        </v-layout>

        <main-table-component
          :headers="headersRc"
          :items="computedTableItemsHarian"
          v-if="activeTabLaporan == 0"
          :search="search"
        />
      </v-tab-item>
    </v-tabs>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ChartComponent from "@/components/ChartComponent";
import MainTableComponent from "@/components/MainTableComponent.vue";

export default {
  components: {
    ChartComponent,
    MainTableComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
      }
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      if (this.activeTab == "RC.01") {
        if (this.activeTabLaporan == 0) {
          const response = await this.$axios.get(`/all-rc01`, {
            params: { ...params, type: "days" },
          });
          this.tableItems = response.data.data.rc01;
        } else {
          const response = await this.$axios.get(`/all-rc01`, {
            params: { ...params, type: "reports" },
          });
          this.tableItems = response.data.data.rc01;
        }
      }
    },
    async download() {
      let type;
      if (this.activeTabLaporan == 0) {
        type = "days";
      } else {
        type = "reports";
      }
      const queryParams = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      let urlDownload, fileName;
      if (this.activeTab == "RC.01") {
        urlDownload = `/download-list-rc01/${type}`;
        fileName = `Kendaraan Rescue_RC01_${this.dateFrom}_${this.dateTo}.xlsx`;
      } else {
        urlDownload = `/download-list-rc02`;
        fileName = `Kendaraan Rescue_RC02_${this.dateFrom}_${this.dateTo}.xlsx`;
      }

      try {
        const response = await this.$axios.get(urlDownload, {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((data) => ({
        ...data,
        asset_damaged: data.asset_damaged ? "Merusak" : "Tidak Merusak",
        compensation: data.compensation ? "Ganti Rugi" : "Tidak Ada Ganti Rugi",
        href: `/lalu-lintas/rescue/laporan/${data.id}`,
        location: `KM ${data.kilometer ?? 0} - ${data.track} - ${data.lane}`,
        start_end_km: `${data.start_kilometer} - ${data.end_kilometer}`,
        achievement: data.end_kilometer - data.start_kilometer,
      }));
    },
    computedTableItemsHarian() {
      return this.tableItems.map((data) => ({
        ...data,
        incident_time: data.incident_time.split(" ")[0],
        total: `Total Laporan: ${data.total_incident}`,
        href: `/lalu-lintas/rescue/harian?date=${data.incident_time}`,
      }));
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      activeTab: "RC.01",
      activeTabLaporan: 0,
      dateFrom: null,
      dateTo: null,
      headersHarian: [
        {
          text: "Waktu Kejadian",
          align: "start",
          value: "incident_time",
        },
        { text: "Total Laporan", value: "total" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      headers: [
        {
          text: "Waktu Kejadian",
          align: "start",
          value: "incident_time",
        },
        { text: "Lokasi Kejadian", value: "location" },
        { text: "KM Awal - KM Akhir", value: "start_end_km" },
        { text: "Pencapaian", value: "achievement" },
        { text: "Uraian Kejadian", value: "incident_description" },
        { text: "Uraian Tindak Lanjut", value: "handling_measures" },
        {
          text: "Foto",
          value: "image_urls",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      headersRc: [
        {
          text: "Tanggal Pengecekan",
          align: "start",
          value: "incident_time",
        },
        { text: "KM Awal", value: "location" },
        { text: "KM Akhir", value: "km" },
        { text: "Pencapaian", value: "type" },
        { text: "BBM", value: "handling_measures" },
        { text: "Shift", value: "handling_measures" },
        { text: "Petugas", value: "petugas" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
      crumbs: [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Kendaraan Rescue",
          disabled: true,
          href: "/lalu-lintas/rescue",
        },
      ],
      search: "",
    };
  },
};
</script>

<style scoped>
.v-item--active {
  background-color: #abd1c6 !important;
}
</style>
