<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <v-card class="px-8 py-8 rounded-xl">
          <v-row>
            <v-col>
              <h5>KM Awal</h5>
              <p class="body-2 mt-1">{{ data?.start_kilometer || "-" }}</p>
            </v-col>
            <v-col>
              <h5>KM Akhir</h5>
              <p class="body-2 mt-1">{{ data?.end_kilometer || "-" }}</p>
            </v-col>
            <v-col>
              <h5>Pencapaian</h5>
              <p class="body-2 mt-1">
                {{ data?.end_kilometer - data?.start_kilometer || "-" }}
              </p>
            </v-col>
            <v-col>
              <h5>BBM</h5>
              <p class="body-2 mt-1">{{ data?.bbm }}</p>
            </v-col>
            <v-col>
              <h5>Shift</h5>
              <p class="body-2 mt-1">{{ data?.shift }}</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="px-8 py-8 rounded-xl" height="100%">
          <h3 class="mb-5">Perlengkapan Tugas</h3>

          <template v-for="(item, index) in computedFields1">
            <item-detail-component
              :key="index"
              :name="item.name"
              :desc="item.desc"
              :avail="item.avail"
              :condition="item.condition"
              :info="item.info"
            />
          </template>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="px-8 py-8 rounded-xl" height="100%">
          <h3 class="mb-5">Sarana Tugas</h3>
          <v-row>
            <template v-for="(item, index) in computedFields2">
              <v-col :key="index" cols="4" class="py-0">
                <item-detail-component
                  :key="index"
                  :name="item.name"
                  :desc="item.desc"
                  :avail="item.avail"
                  :condition="item.condition"
                  :info="item.info"
                />
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card class="px-8 py-8 rounded-xl" height="100%">
          <h3 class="mb-5">Kondisi Kendaraan</h3>
          <template v-for="(item, index) in computedFields3">
            <item-detail-component
              :key="index"
              :name="item.name"
              :desc="item.desc"
              :avail="item.avail"
              :condition="item.condition"
              :info="item.info"
              :isWithoutAvail="true"
            />
          </template>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="px-8 py-8 rounded-xl" height="100%">
          <h3 class="mb-5">Perlengkapan Kendaraan</h3>
          <v-row>
            <template v-for="(item, index) in computedFields4">
              <v-col :key="index" cols="4" class="py-0">
                <item-detail-component
                  :key="index"
                  :name="item.name"
                  :desc="item.desc"
                  :avail="item.avail"
                  :condition="item.condition"
                  :info="item.info"
                />
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import ItemDetailComponent from "@/components/ItemDetailComponent.vue";
export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    ItemDetailComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(`/pd02/${this.id}`);
        this.data = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(`/pd02/${this.id}`);
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/derek");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    async download() {
      try {
        const response = await this.$axios.get(`/download-pd02/${this.id}`);
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Kendaraan Derek_PDO2_${this.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    formatDate(date) {
      return date ? date.slice(-8) : "-";
    },
  },
  computed: {
    title() {
      return `Detail Cek Kelengkapan - PD.02 (${this.id})`;
    },
    crumbs() {
      return [
        {
          text: "Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Kendaraan Derek",
          disabled: false,
          href: "/lalu-lintas/derek",
        },
        {
          text: "Per Laporan",
          disabled: false,
          href: "/lalu-lintas/derek",
        },
        {
          text: "Kendaraan Derek",
          disabled: true,
          href: `/lalu-lintas/derek/laporan/${this.id}`,
        },
      ];
    },
    computedFields1() {
      return this.fields_1.map((item) => {
        const availKey = item.objKey + "_available";
        const conditionKey = item.objKey + "_condition";
        const noteKey = item.objKey + "_note";
        return {
          ...item,
          avail: this.data[availKey] ? "Ada" : "Tidak Ada",
          condition: this.data[conditionKey],
          info: this.data[noteKey],
        };
      });
    },
    computedFields2() {
      return this.fields_2.map((item) => {
        const availKey = item.objKey + "_available";
        const conditionKey = item.objKey + "_condition";
        const noteKey = item.objKey + "_note";
        return {
          ...item,
          avail: this.data[availKey] ? "Ada" : "Tidak Ada",
          condition: this.data[conditionKey],
          info: this.data[noteKey],
        };
      });
    },
    computedFields3() {
      return this.fields_3.map((item) => {
        const conditionKey = item.objKey + "_condition";
        const noteKey = item.objKey + "_note";
        return {
          ...item,
          condition: this.data[conditionKey],
          info: this.data[noteKey],
        };
      });
    },
    computedFields4() {
      return this.fields_4.map((item) => {
        const availKey = item.objKey + "_available";
        const conditionKey = item.objKey + "_condition";
        const noteKey = item.objKey + "_note";
        return {
          ...item,
          avail: this.data[availKey] ? "Ada" : "Tidak Ada",
          condition: this.data[conditionKey],
          info: this.data[noteKey],
        };
      });
    },
  },
  data() {
    return {
      data: {},
      id: this.$route.params.id,
      snackbar: false,
      message: "",
      timeout: 3000,
      carousel: null,
      fields_1: [
        {
          name: "Rompi Reflektif",
          desc: "Standar (1 Buah)",
          objKey: "rompi_reflektif",
        },
        {
          name: "Topi Lapangan",
          desc: "Standar (1 Buah)",
          objKey: "topi_lapangan",
        },
        {
          name: "Jas Hujan",
          desc: "Standar (1 Buah)",
          objKey: "jas_hujan",
        },
      ],
      fields_2: [
        {
          name: "Sepatu Kerja",
          desc: "Standar (1 Pasang)",
          objKey: "sepatu_kerja",
        },
        {
          name: "Sarung Tangan Glove",
          desc: "Standar (1 Pasang)",
          objKey: "sarung_tangan_glove",
        },
        {
          name: "Masker",
          desc: "Standar (2 Buah)",
          objKey: "masker",
        },
        {
          name: "Helm Reflektif",
          desc: "Standar (1 Buah)",
          objKey: "helm_reflektif",
        },
        {
          name: "Kacamata Goggles",
          desc: "Standar (1 Buah)",
          objKey: "kacamata_goggles",
        },
        {
          name: "Bendera Merah",
          desc: "Standar (1 Buah)",
          objKey: "bendera_merah",
        },
        {
          name: "Rambu",
          desc: "Standar (1 Buah)",
          objKey: "rambu",
        },
        {
          name: "Senter Lalu Lintas (Warna Merah)",
          desc: "Standar (1 Buah)",
          objKey: "senter_lalu_lintas",
        },
        {
          name: "Rantai + Sling",
          desc: "Standar (1 Unit)",
          objKey: "rantai_sling",
        },
        {
          name: "Rubber Cone",
          desc: "Standar (3 Buah)",
          objKey: "rubber_cone",
        },
        {
          name: "Tool Set",
          desc: "Standar (1 Set)",
          objKey: "tool_set",
        },
        {
          name: "Terpal Penutup",
          desc: "Standar (1 Buah)",
          objKey: "terpal_penutup",
        },
        {
          name: "Senter/Lampu Penerangan",
          desc: "Standar (1 Buah)",
          objKey: "senter_lampu_penerangan",
        },
      ],
      fields_3: [
        {
          name: "Oli",
          objKey: "oli",
        },
        {
          name: "Aki",
          objKey: "aki",
        },
        {
          name: "Wiper",
          objKey: "wiper",
        },
        {
          name: "Air Radiator",
          objKey: "air_radiator",
        },
      ],
      fields_4: [
        {
          name: "STNK",
          desc: "Standar (1 Buah)",
          objKey: "stnk",
        },
        {
          name: "Buku KIR",
          desc: "Standar (1 Buah)",
          objKey: "buku_kir",
        },
        {
          name: "Kaca Spion Luar Dalam",
          desc: "Standar (1 Buah)",
          objKey: "kaca_spion",
        },
        {
          name: "Radio Komunikasi + Antena",
          desc: "Standar (1 Buah)",
          objKey: "radio_komunikasi_antena",
        },
        {
          name: "Rotator",
          desc: "Standar (1 Buah)",
          objKey: "rotator",
        },
        {
          name: "Sirine + Public Address",
          desc: "Standar (1 Buah)",
          objKey: "sirine_public_address",
        },
        {
          name: "Dongkrak + Tangkai",
          desc: "Standar (1 Buah)",
          objKey: "dongkrak_tangkai",
        },
        {
          name: "Ban Serep",
          desc: "Standar (1 Buah)",
          objKey: "ban_serep",
        },
        {
          name: "Kunci Pas/Ring (Tool Kit)",
          desc: "Standar (1 Buah)",
          objKey: "kunci_pas",
        },
        {
          name: "Segitiga Pengaman",
          desc: "Standar (1 Buah)",
          objKey: "segitiga_pengaman",
        },
        {
          name: "Kunci Roda",
          desc: "Standar (1 Buah)",
          objKey: "kunci_roda",
        },
        {
          name: "Kotak P3K",
          desc: "Standar (1 Buah)",
          objKey: "kotak_p3k",
        },
        {
          name: "Radio Tape + Antena",
          desc: "Standar (1 Buah)",
          objKey: "radio_tape_antena",
        },
      ],
    };
  },
};
</script>

<style></style>
