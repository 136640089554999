<template>
  <div class="card-container px-8 py-8" style="height: 100%">
    <div v-if="desc_initial !== undefined || desc_initial != null">
      <h6>Deskripsi Kondisi Aset</h6>
      <p class="desc-value">{{ desc_initial }}</p>
    </div>
    <div v-if="descFollowUp !== undefined || descFollowUp != null">
      <h6>Deskripsi Tindak Lanjut</h6>
      <p class="desc-value">{{ descFollowUp }}</p>
    </div>
    <div v-if="descFinal !== undefined || descFinal != null">
      <h6>Deskripsi Finalisasi</h6>
      <p class="desc-value">{{ descFinal }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["desc_initial", "descFollowUp", "descFinal"],
};
</script>

<style>
.card-container {
  box-shadow: 0px 2px 6px #e8e4e6;
  border-radius: 20px;
}

h6 {
  font-size: 14px;
}

.desc-value {
  font-size: 16px;
  font-weight: 300;
  word-wrap: break-word;
}
</style>
