<template>
  <v-app v-if="!isLoading">
    <v-row style="margin: 0 !important">
      <sidebar-component v-if="!isLoginPage" />
      <v-main class="background-container3" :class="isProfilePage">
        <router-view />
      </v-main>
    </v-row>
  </v-app>
</template>

<script>
import SidebarComponent from "@/components/SidebarComponent.vue";
import Cookies from "js-cookie";

export default {
  components: {
    SidebarComponent,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.path === "/login" || this.$route.path === "/";
    },
    isProfilePage() {
      return this.$route.path === "/profile" ? "background-container4" : "";
    },
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.path !== "/login") {
        if (Cookies.get("loginResponse") == undefined) {
          window.location.replace("/login");
        } else {
          this.account = JSON.parse(Cookies.get("loginResponse"));
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.background-container3 {
  background-image: url("~@/assets/illustration.svg");
  background-position: top right;
}

.background-container4 {
  background-image: url("~@/assets/illustration_y_2.svg"),
    url("~@/assets/illustration_y.svg");
  background-position: top right, bottom left 60px;
}
</style>
