<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>

    <template v-if="dataDetail['asset_damaged']">
      <v-row class="mt-4">
        <v-col cols="4" md="4" sm="12">
          <photo-group-carousel-component :images="images" />
        </v-col>
        <v-col>
          <v-card class="px-8 py-8 rounded-xl" height="100%">
            <v-row>
              <v-col>
                <h5>Merusak Aset</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["asset_damaged"]
                      ? dataDetail["asset_damaged"] == true
                        ? "Ya"
                        : "Tidak"
                      : "-"
                  }}
                </p>
                <h5>Kerugian</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["compensation"]
                      ? dataDetail["compensation"] == false
                        ? "Ganti Rugi"
                        : "Tidak Ada Ganti Rugi"
                      : "-"
                  }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-carousel class="body-2 mt-1" v-model="model" height="none">
                  <v-carousel-item
                    v-for="(asset, idx) in dataDetail?.damaged_assets"
                    :key="idx"
                    class="px-8"
                  >
                    <damaged-asset-card-component :asset="asset" :idx="idx" />
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="6" md="6" sm="12">
              <v-card class="px-8 py-8 rounded-xl" height="100%">
                <h5>Sumber Informasi</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["source_information"]
                      ? dataDetail["source_information"]
                      : "-"
                  }}
                </p>
                <h5>Waktu Kejadian</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["incident_time"]
                      ? dataDetail["incident_time"].slice(-8)
                      : "-"
                  }}
                </p>
                <h5>Waktu Informasi Diterima</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["information_accepted_time"]
                      ? dataDetail["information_accepted_time"].slice(-8)
                      : "-"
                  }}
                </p>
                <h5>Shift</h5>
                <p class="body-2 mt-1">
                  {{ dataDetail["shift"] ? dataDetail["shift"] : "-" }}
                </p>

                <div class="mb-4">
                  <h4 class="mb-3">Lokasi Kejadian</h4>
                  <div
                    style="display: flex; flex-direction: row"
                    class="justify-space-between"
                  >
                    <div style="border-left: 1px #abd1c6 solid" class="px-3">
                      <p style="font-size: 12px; color: #6c757d" class="mb-2">
                        KM
                      </p>
                      <p class="body-2">
                        {{
                          dataDetail["kilometer"]
                            ? dataDetail["kilometer"]
                            : "-"
                        }}
                      </p>
                    </div>
                    <div
                      style="border-left: 1px #abd1c6 solid"
                      class="body-2 px-3"
                    >
                      <p
                        style="font-size: 12px; color: #6c757d"
                        class="body-2 mb-2"
                      >
                        Jalur
                      </p>
                      <p class="body-2">
                        {{ dataDetail["track"] ? dataDetail["track"] : "-" }}
                      </p>
                    </div>
                    <div style="border-left: 1px #abd1c6 solid" class="px-3">
                      <p
                        style="font-size: 12px; color: #6c757d"
                        class="body-2 mb-2"
                      >
                        Lajur
                      </p>
                      <p class="body-2">
                        {{ dataDetail["lane"] ? dataDetail["lane"] : "-" }}
                      </p>
                    </div>
                  </div>
                </div>
                <h5>Kategori/Jenis Laka Lantas</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["accident_type"]
                      ? dataDetail["accident_type"]
                      : "-"
                  }}
                </p>
                <h5>Waktu Pembersihan</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["cleaning_time"]
                      ? dataDetail["cleaning_time"].slice(-8)
                      : "-"
                  }}
                </p>
                <h5>Waktu Pembukaan Jalur</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["track_opening_time"]
                      ? dataDetail["cleaning_time"].slice(-8)
                      : "-"
                  }}
                </p>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-card class="px-8 py-8 rounded-xl" height="100%">
                <h5>Angin</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["wind_condition"]
                      ? dataDetail["wind_condition"]
                      : "-"
                  }}
                </p>
                <h5>Keadaan Cuaca</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["weather_condition"]
                      ? dataDetail["weather_condition"]
                      : "-"
                  }}
                </p>
                <h5>Lampu Penerangan Jalan</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["lighting"]
                      ? dataDetail["lighting"] == true
                        ? "Ada"
                        : "Tidak Ada"
                      : "-"
                  }}
                </p>
                <h5>Permukaan Jalan</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["road_surface"]
                      ? dataDetail["road_surface"]
                      : "-"
                  }}
                </p>
                <h5>Geometrik Jalan</h5>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["geometric_type"]
                      ? dataDetail["geometric_type"]
                      : "-"
                  }}
                </p>
                <h5>Kerusakan</h5>
                <p class="body-2 mt-1">
                  {{ dataDetail["loss"] ? dataDetail["loss"] : "-" }}
                </p>
                <h4>Penutupan Jalur</h4>
                <p class="body-2 mt-1">
                  {{
                    dataDetail["lane_closure_type"]
                      ? dataDetail["lane_closure_type"]
                      : "-"
                  }}
                </p>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="px-8 py-8 rounded-xl" height="100%">
                <v-row>
                  <v-col>
                    <h5>Faktor Mempengaruhi (Penyebab)</h5>
                    <p class="body-2 mt-1">
                      {{ dataDetail["reason"] ? dataDetail["reason"] : "-" }}
                    </p>
                    <h5>Tindakan Penanganan</h5>
                    <p class="body-2 mt-1">
                      {{
                        dataDetail["handling_measures"]
                          ? dataDetail["handling_measures"]
                          : "-"
                      }}
                    </p>
                  </v-col>
                  <v-col>
                    <h5>Kronologis</h5>
                    <p class="body-2 mt-1">
                      {{
                        dataDetail["chronological"]
                          ? dataDetail["chronological"]
                          : "-"
                      }}
                    </p>
                    <h5>Kelanjutan Perkara</h5>
                    <p class="body-2 mt-1">
                      {{
                        dataDetail["case_continuation"]
                          ? dataDetail["case_continuation"]
                          : "-"
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-card class="px-8 py-8 rounded-xl">
            <v-container>
              <v-row class="justify-space-between align-center mb-3">
                <h5>Jumlah Kendaraan Terlibat</h5>
                <v-col cols="12">
                  <v-row>
                    <score-card-component
                      score-label="Gol 1"
                      :score-value="this.scoreValue['Golongan 1']"
                    />
                    <score-card-component
                      score-label="Gol 2"
                      :score-value="this.scoreValue['Golongan 2']"
                    />
                    <score-card-component
                      score-label="Gol 3"
                      :score-value="this.scoreValue['Golongan 3']"
                    />
                    <score-card-component
                      score-label="Gol 4"
                      :score-value="this.scoreValue['Golongan 4']"
                    />
                    <score-card-component
                      score-label="Gol 5"
                      :score-value="this.scoreValue['Golongan 5']"
                    />
                    <score-card-component
                      score-label="Total"
                      :score-value="
                        this.scoreValue['Golongan 1'] +
                        this.scoreValue['Golongan 2'] +
                        this.scoreValue['Golongan 3'] +
                        this.scoreValue['Golongan 4'] +
                        this.scoreValue['Golongan 5']
                      "
                      class="pr-0"
                    />
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                style="display: flex"
                class="justify-space-between align-center mb-3"
                id="vehicles"
              >
                <h5 class="mb-4">Identitas Kendaraan dan Pengemudi</h5>
                <v-carousel :show-arrows="false" style="height: auto">
                  <v-carousel-item
                    v-for="(data, index) in vehicles"
                    v-bind:key="data"
                  >
                    <two-col-table-component
                      class="mb-1"
                      :title1="'Kendaraan ' + (parseInt(index) + 1)"
                      :title2="'Pengemudi ' + (parseInt(index) + 1)"
                      :data-detail-one="doMapDataToVehicle(data)"
                      :data-detail-two="doMapDataToDriver(data)"
                      :images="data['images']"
                    />
                  </v-carousel-item>
                </v-carousel>
              </v-row>
              <v-row
                style="display: flex"
                class="justify-space-between align-center mb-3"
              >
                <div>
                  <h5 class="mb-2">Jumlah Korban</h5>
                  <div>{{ countKorban }}</div>
                </div>
              </v-row>
              <v-row
                style="display: flex; flex-direction: column"
                class="justify-space-between mb-3"
              >
                <h5 class="mb-2">Data Korban</h5>
                <v-carousel :show-arrows="false" style="height: auto">
                  <v-carousel-item
                    v-for="victimList in victims"
                    :key="victimList"
                  >
                    <v-row
                      style="display: flex"
                      class="justify-space-between mb-1"
                      id="victims"
                    >
                      <detail-card-table-component
                        cols="6"
                        v-for="(data, index) in victimList"
                        v-bind:key="data"
                        :title="'Korban ' + (parseInt(index) + 1)"
                        :detail-data="doMapDataToLabelVictims(data)"
                        :image="data['image_url']"
                      />
                    </v-row>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
              <v-row
                style="display: flex; flex-direction: column"
                class="justify-space-between mb-3"
              >
                <h5 class="mb-2">Petugas Terlibat</h5>
                <v-carousel :show-arrows="false" style="height: auto">
                  <v-carousel-item
                    v-for="officerList in officers"
                    :key="officerList"
                  >
                    <v-row
                      style="display: flex"
                      class="justify-space-between mb-1"
                      id="officers"
                    >
                      <detail-card-table-component
                        cols="6"
                        v-for="officer in officerList"
                        :key="officer"
                        :title="officer.unit"
                        :detail-data="doMapDataToLabelOfficers(officer)"
                      />
                    </v-row>
                  </v-carousel-item>
                </v-carousel>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row v-else class="mt-4">
      <v-col cols="8">
        <v-row>
          <v-col cols="6" md="6" sm="12">
            <photo-group-carousel-component :images="images" />
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <v-card class="px-8 py-8 rounded-xl" height="100%">
              <h5>Merusak Aset</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["asset_damaged"]
                    ? dataDetail["asset_damaged"] == true
                      ? "Ya"
                      : "Tidak"
                    : "-"
                }}
              </p>
              <h5>Kerugian</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["compensation"]
                    ? dataDetail["compensation"] == false
                      ? "Ya"
                      : "Tidak"
                    : "-"
                }}
              </p>
            </v-card>
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <v-card class="px-8 py-8 rounded-xl" height="100%">
              <h5>Sumber Informasi</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["source_information"]
                    ? dataDetail["source_information"]
                    : "-"
                }}
              </p>
              <h5>Waktu Kejadian</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["incident_time"]
                    ? dataDetail["incident_time"].slice(-8)
                    : "-"
                }}
              </p>
              <h5>Waktu Informasi Diterima</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["information_accepted_time"]
                    ? dataDetail["information_accepted_time"].slice(-8)
                    : "-"
                }}
              </p>
              <h5>Shift</h5>
              <p class="body-2 mt-1">
                {{ dataDetail["shift"] ? dataDetail["shift"] : "-" }}
              </p>

              <div class="mb-4">
                <h4 class="mb-3">Lokasi Kejadian</h4>
                <div
                  style="display: flex; flex-direction: row"
                  class="justify-space-between"
                >
                  <div style="border-left: 1px #abd1c6 solid" class="px-3">
                    <p style="font-size: 12px; color: #6c757d" class="mb-2">
                      KM
                    </p>
                    <p class="body-2">
                      {{
                        dataDetail["kilometer"] ? dataDetail["kilometer"] : "-"
                      }}
                    </p>
                  </div>
                  <div
                    style="border-left: 1px #abd1c6 solid"
                    class="body-2 px-3"
                  >
                    <p
                      style="font-size: 12px; color: #6c757d"
                      class="body-2 mb-2"
                    >
                      Jalur
                    </p>
                    <p class="body-2">
                      {{ dataDetail["track"] ? dataDetail["track"] : "-" }}
                    </p>
                  </div>
                  <div style="border-left: 1px #abd1c6 solid" class="px-3">
                    <p
                      style="font-size: 12px; color: #6c757d"
                      class="body-2 mb-2"
                    >
                      Lajur
                    </p>
                    <p class="body-2">
                      {{ dataDetail["lane"] ? dataDetail["lane"] : "-" }}
                    </p>
                  </div>
                </div>
              </div>
              <h5>Kategori/Jenis Laka Lantas</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["accident_type"]
                    ? dataDetail["accident_type"]
                    : "-"
                }}
              </p>
              <h5>Waktu Pembersihan</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["cleaning_time"]
                    ? dataDetail["cleaning_time"].slice(-8)
                    : "-"
                }}
              </p>
              <h5>Waktu Pembukaan Jalur</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["track_opening_time"]
                    ? dataDetail["cleaning_time"].slice(-8)
                    : "-"
                }}
              </p>
            </v-card>
          </v-col>
          <v-col cols="6" md="6" sm="12">
            <v-card class="px-8 py-8 rounded-xl" height="100%">
              <h5>Angin</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["wind_condition"]
                    ? dataDetail["wind_condition"]
                    : "-"
                }}
              </p>
              <h5>Keadaan Cuaca</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["weather_condition"]
                    ? dataDetail["weather_condition"]
                    : "-"
                }}
              </p>
              <h5>Lampu Penerangan Jalan</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["lighting"]
                    ? dataDetail["lighting"] == true
                      ? "Ada"
                      : "Tidak Ada"
                    : "-"
                }}
              </p>
              <h5>Permukaan Jalan</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["road_surface"] ? dataDetail["road_surface"] : "-"
                }}
              </p>
              <h5>Geometrik Jalan</h5>
              <p class="body-2 mt-1">
                {{
                  dataDetail["geometric_type"]
                    ? dataDetail["geometric_type"]
                    : "-"
                }}
              </p>
              <h5>Kerusakan</h5>
              <p class="body-2 mt-1">
                {{ dataDetail["loss"] ? dataDetail["loss"] : "-" }}
              </p>
              <h4>Penutupan Jalur</h4>
              <p class="body-2 mt-1">
                {{
                  dataDetail["lane_closure_type"]
                    ? dataDetail["lane_closure_type"]
                    : "-"
                }}
              </p>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="px-8 py-8 rounded-xl" height="100%">
              <v-row>
                <v-col>
                  <h5>Faktor Mempengaruhi (Penyebab)</h5>
                  <p class="body-2 mt-1">
                    {{ dataDetail["reason"] ? dataDetail["reason"] : "-" }}
                  </p>
                  <h5>Tindakan Penanganan</h5>
                  <p class="body-2 mt-1">
                    {{
                      dataDetail["handling_measures"]
                        ? dataDetail["handling_measures"]
                        : "-"
                    }}
                  </p>
                </v-col>
                <v-col>
                  <h5>Kronologis</h5>
                  <p class="body-2 mt-1">
                    {{
                      dataDetail["chronological"]
                        ? dataDetail["chronological"]
                        : "-"
                    }}
                  </p>
                  <h5>Kelanjutan Perkara</h5>
                  <p class="body-2 mt-1">
                    {{
                      dataDetail["case_continuation"]
                        ? dataDetail["case_continuation"]
                        : "-"
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card class="px-8 py-8 rounded-xl">
          <v-container>
            <v-row class="justify-space-between align-center mb-3">
              <h5>Jumlah Kendaraan Terlibat</h5>
              <v-col cols="12">
                <v-row>
                  <score-card-component
                    score-label="Gol 1"
                    :score-value="this.scoreValue['Golongan 1']"
                  />
                  <score-card-component
                    score-label="Gol 2"
                    :score-value="this.scoreValue['Golongan 2']"
                  />
                  <score-card-component
                    score-label="Gol 3"
                    :score-value="this.scoreValue['Golongan 3']"
                  />
                  <score-card-component
                    score-label="Gol 4"
                    :score-value="this.scoreValue['Golongan 4']"
                  />
                  <score-card-component
                    score-label="Gol 5"
                    :score-value="this.scoreValue['Golongan 5']"
                  />
                  <score-card-component
                    score-label="Total"
                    :score-value="
                      this.scoreValue['Golongan 1'] +
                      this.scoreValue['Golongan 2'] +
                      this.scoreValue['Golongan 3'] +
                      this.scoreValue['Golongan 4'] +
                      this.scoreValue['Golongan 5']
                    "
                    class="pr-0"
                  />
                </v-row>
              </v-col>
            </v-row>
            <v-row
              style="display: flex"
              class="justify-space-between align-center mb-3"
              id="vehicles"
            >
              <h5 class="mb-4">Identitas Kendaraan dan Pengemudi</h5>
              <v-carousel :show-arrows="false" style="height: auto">
                <v-carousel-item
                  v-for="(data, index) in vehicles"
                  v-bind:key="data"
                >
                  <two-col-table-component
                    class="mb-1"
                    :title1="'Kendaraan ' + (parseInt(index) + 1)"
                    :title2="'Pengemudi ' + (parseInt(index) + 1)"
                    :data-detail-one="doMapDataToVehicle(data)"
                    :data-detail-two="doMapDataToDriver(data)"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-row>
            <v-row
              style="display: flex"
              class="justify-space-between align-center mb-3"
            >
              <div>
                <h5 class="mb-2">Jumlah Korban</h5>
                <div>{{ countKorban }}</div>
              </div>
            </v-row>
            <v-row
              style="display: flex; flex-direction: column"
              class="justify-space-between mb-3"
            >
              <h5 class="mb-2">Data Korban</h5>
              <v-carousel :show-arrows="false" style="height: auto">
                <v-carousel-item
                  v-for="victimList in victims"
                  :key="victimList"
                >
                  <v-row
                    style="display: flex"
                    class="justify-space-between mb-1"
                    id="victims"
                  >
                    <detail-card-table-component
                      cols="6"
                      v-for="(data, index) in victimList"
                      v-bind:key="data"
                      :title="'Korban ' + (parseInt(index) + 1)"
                      :detail-data="doMapDataToLabelVictims(data)"
                    />
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-row>
            <v-row
              style="display: flex; flex-direction: column"
              class="justify-space-between mb-3"
            >
              <h5 class="mb-2">Petugas Terlibat</h5>
              <v-carousel :show-arrows="false" style="height: auto">
                <v-carousel-item
                  v-for="officerList in officers"
                  :key="officerList"
                >
                  <v-row
                    style="display: flex"
                    class="justify-space-between mb-1"
                    id="officers"
                  >
                    <detail-card-table-component
                      cols="6"
                      v-for="officer in officerList"
                      :key="officer"
                      :title="officer.unit"
                      :detail-data="doMapDataToLabelOfficers(officer)"
                    />
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PhotoGroupCarouselComponent from "@/components/PhotoGroupCarouselComponent";
import DetailCardTableComponent from "@/components/DetailCardTableComponent";
import TwoColTableComponent from "@/components/TwoColTableComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import TitleComponent from "@/components/TitleComponent";
import ScoreCardComponent from "@/components/ScoreCardComponent";
import DamagedAssetCardComponent from "@/components/DamagedAssetCardComponent.vue";

export default {
  components: {
    PhotoGroupCarouselComponent,
    DetailCardTableComponent,
    TwoColTableComponent,
    TitleComponent,
    BreadcrumbsComponent,
    ScoreCardComponent,
    DamagedAssetCardComponent,
  },
  beforeMount() {
    this.getDataDetail();
  },
  methods: {
    async getDataDetail() {
      await this.$axios.get(`/accidents/${this?.$route?.params?.id}`).then(
        (response) => {
          let data = response.data["data"];
          this.dataDetail = data;
          this.victims = data["victims"];
          this.officers = data["officers"];
          this.vehicles = data["vehicles"];
          this.idData = data["id"];

          this.countKorban = this.victims.length;
          this.images = data["image_urls"];

          for (let i = 0; i < this.vehicles.length; i++) {
            this.scoreValue[this.vehicles[i]["vehicle_class"]] =
              this.scoreValue[this.vehicles[i]["vehicle_class"]] + 1;
          }

          var slicedData = [];

          for (let i = 0; i < this.officers.length; i += 2) {
            let tempArr = [];
            tempArr.push(this.officers[i]);

            if (this.officers[i + 1]) tempArr.push(this.officers[i + 1]);

            slicedData.push(tempArr);
          }

          this.officers = slicedData;
          slicedData = [];

          for (let i = 0; i < this.victims.length; i += 2) {
            let tempArr = [];
            tempArr.push(this.victims[i]);

            if (this.victims[i + 1]) tempArr.push(this.victims[i + 1]);

            slicedData.push(tempArr);
          }

          this.victims = slicedData;

          this.crumbs[2].text = "Detail Kecelakaan (" + this.idData + ")";
          this.crumbs[2].href = "detail-kecelakaan/" + this.idData;
          this.title = "Detail Kecelakaan (" + this.idData + ")";
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(`/accidents/${this?.$route?.params?.id}`);
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/kecelakaan");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    doMapDataToLabelOfficers(data) {
      let timeArrival = new Date(data["arrival_time"]);
      let timeCompletion = new Date(data["completion_time"]);
      timeArrival =
        timeArrival.getHours() +
        ":" +
        timeArrival.getMinutes().toString().padStart(2, "0");
      timeCompletion =
        timeCompletion.getHours() +
        ":" +
        timeCompletion.getMinutes().toString().padStart(2, "0");
      return [
        {
          labelName: "Nama Petugas Unit Terkait",
          value: data["officer"] ? data["officer"] : "-",
        },
        {
          labelName: "Waktu Tiba",
          value: data["arrival_time"] ? timeArrival : "-",
        },
        {
          labelName: "Waktu Selesai",
          value: data["completion_time"] ? timeCompletion : "-",
        },
        {
          labelName: "Tindakan Unit Terkait",
          value: data["actions"] ? data["actions"] : "-",
        },
      ];
    },
    doMapDataToLabelVictims(data) {
      return [
        {
          labelName: "Nama",
          value: data["victim_name"] ? data["victim_name"] : "-",
        },
        {
          labelName: "Jenis Kelamin",
          value: data["gender"] ? data["gender"] : "-",
        },
        {
          labelName: "Umur",
          value: data["age"] ? data["age"] : "-",
        },
        {
          labelName: "Alamat",
          value: data["victim_adress"] ? data["victim_adress"] : "-",
        },
        {
          labelName: "Uraian Luka",
          value: data["wound_description"] ? data["wound_description"] : "-",
        },
      ];
    },
    doMapDataToVehicle(data) {
      return [
        {
          labelName: "Golongan",
          value: data["vehicle_class"] ? data["vehicle_class"] : "-",
        },
        {
          labelName: "Jenis Kendaraan",
          value: data["vehicle_type"] ? data["vehicle_type"] : "-",
        },
        {
          labelName: "Merk",
          value: data["brand"] ? data["brand"] : "-",
        },
        {
          labelName: "Nomor Polisi",
          value: data["police_number"] ? data["police_number"] : "-",
        },
      ];
    },
    doMapDataToDriver(data) {
      return [
        {
          labelName: "Nama Pengemudi",
          value: data["driver_name"] ? data["driver_name"] : "-",
        },
        {
          labelName: "Umur",
          value: data["age"] ? data["age"] : "-",
        },
        {
          labelName: "Alamat",
          value: data["address"] ? data["address"] : "-",
        },
        {
          labelName: "Pekerjaan",
          value: data["occupation"] ? data["occupation"] : "-",
        },
        {
          labelName: "Jenis SIM",
          value: data["license_type"] ? data["license_type"] : "-",
        },
        {
          labelName: "Nomor SIM",
          value: data["license_number"] ? data["license_number"] : "-",
        },
      ];
    },
    async download() {
      try {
        const response = await this.$axios.get(
          `/download-accident/${this?.$route?.params?.id}`
        );
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Detail Kecelakaan ${this?.$route?.params?.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    idData: "",
    countKorban: 3,
    title: "Detail Korban",
    vehicles: [],
    officers: [],
    victims: [],
    images: [],
    scoreValue: {
      "Golongan 1": 0,
      "Golongan 2": 0,
      "Golongan 3": 0,
      "Golongan 4": 0,
      "Golongan 5": 0,
    },
    dataDetail: null,
    crumbs: [
      {
        text: "Layanan Lalu Lintas",
        disabled: false,
        href: "/lalu-lintas",
      },
      {
        text: "Kecelakaan",
        disabled: false,
        href: "/lalu-lintas/kecelakaan",
      },
      {
        text: "Detail Kecelakaan (1)",
        disabled: true,
        href: "detail-kecelakaan/1",
      },
    ],
  }),
};
</script>

<style>
h6 {
  color: #001e1d;
}

.v-carousel__item {
  height: auto !important;
}

.v-carousel__controls {
  position: inherit;
  height: auto;
}
</style>
