<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <chart-component
      title="Total Kecelakaan"
      class="mb-8"
      url-chart="/chart-kecelakaan"
      :parentFunc="recallData"
    />

    <table-component
      :headers="headers"
      :items="computedAccidents"
      tableTitle="Laporan Kecelakaan"
      :downloadFunc="openDialogDownload"
    />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogDownload" width="auto">
      <v-card class="px-5 py-5">
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('XLSX')"
        >Download as XLSX</v-btn
        >
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('PDF')"
        >Download as PDF</v-btn
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";

import ChartComponent from "@/components/ChartComponent.vue";
export default {
  components: {
    tableComponent: TableComponent,
    ChartComponent,
    TitleComponent,
    BreadcrumbsComponent,
  },
  beforeMount() {
    this.init();
  },
  computed: {
    computedAccidents() {
      return this.accidents.map((accident) => ({
        ...accident,
        location: `KM ${accident.kilometer ?? 0} - ${accident.track} - ${
          accident.lane
        }`,
        asset_damaged:
          accident.asset_damaged && accident.asset_damaged == true
            ? "Merusak"
            : "Tidak",
        handling_measures: accident.handling_measures ?? "-",
        loss: accident.loss ?? "-",
        foto: accident.image_urls[0],
        total_vehicles: accident.vehicles.length,
        total_victims: accident.victims.length,
        href: `/lalu-lintas/kecelakaan/${accident.id}`,
      }));
    },
  },
  data: () => ({
    title: "Kecelakaan",
    dateFrom: null,
    dateTo: null,
    headers: [
      { text: "Waktu Kejadian", align: "center", value: "created_at" },
      { text: "Lokasi Kejadian", align: "center", value: "location" },
      { text: "Jumlah Kendaraan", align: "center", value: "total_vehicles" },
      { text: "Jumlah Korban", align: "center", value: "total_victims" },
      {
        text: "Uraian Penanganan",
        align: "center",
        value: "handling_measures",
      },
      { text: "Dampak Aset", align: "center", value: "asset_damaged" },
      { text: "Kerugian", align: "center", value: "loss" },
      { text: "Foto", value: "foto", sortable: false, filterable: false },
      {
        text: "",
        value: "arrow",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      },
    ],
    accidents: [],
    date: null,
    date2: null,
    menu: false,
    menu2: false,
    crumbs: [
      {
        text: "Layanan Lalu Lintas",
        disabled: false,
        href: "/lalu-lintas",
      },
      {
        text: "Kecelakaan",
        disabled: true,
        href: "/lalu-lintas/kecelakaan",
      },
    ],
    search: "",
    snackbar: false,
    message: "",
    timeout: 3000,
    dialogDownload: false,
  }),
  methods: {
    async init() {
      const queryParams = {
        from: null,
        to: null,
      };
      const response = await this.$axios.get("/all-accidents", {
        params: queryParams,
      });
      this.accidents = response.data.data.accidents;
    },
    async recallData(dateFrom, dateTo) {
      this.date = dateFrom;
      this.date2 = dateTo;

      const response = await this.$axios.get(
          `/all-accidents?from=${this.date}&to=${this.date2}`
      );

      this.accidents = response.data.data.accidents;
    },
    async downloadAs(format) {
      const queryParams = {
        from: this.date,
        to: this.date2,
      };

      try {
        const endpoint = format === "XLSX" ? "/download-accidents" : "/download-accidents/as-pdf";
        const response = await this.$axios.get(endpoint, {
          params: queryParams,
        });
        const mediaType = format === "XLSX" ? "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," : "data:application/pdf;base64,";
        const base64Data = format === "XLSX" ? response.data.excelData : response.data.pdfData;

        const url = mediaType + base64Data;
        const ext = format === "XLSX" ? "xlsx" : "pdf";

        const a = document.createElement("a");
        a.href = url;
        a.download = `kecelakaan_${this.date}_${this.date2}.${ext}`;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    openDialogDownload() {
      this.dialogDownload = true;
    }
  },
};
</script>

<style></style>
