<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <chart-component
      title="Total Kejadian Khusus"
      class="mb-8"
      url-chart="/chart-kejadian-khusus"
      :parentFunc="updateDate"
    />

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10">
      Laporan Kejadian Khusus
    </h1>

    <v-layout justify-end class="my-3">
      <v-card-title class="ma-0 py-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-card-title>
      <v-btn
        class="white--text text-capitalize"
        color="#004643"
        width="150"
        height="34"
        @click="openDialogDownload"
        >Download</v-btn
      >
    </v-layout>

    <v-data-table
      :headers="tableHeaders"
      :search="search"
      :items="computedTableItems"
      class="elevation-1"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20],
      }"
      :hide-default-footer="false"
    >
      <template v-slot:[`item.officers_and_units`]="{ item }">
        <v-btn
          class="text-capitalize"
          color="#004643"
          width="150"
          height="34"
          outlined
          @click.stop="item.dialog = true"
          >Detail</v-btn
        >
        <v-dialog v-model="item.dialog" width="434">
          <v-card>
            <v-card-title>
              Penanganan Petugas & Unit
              <v-spacer></v-spacer>
              <v-btn icon @click="item.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text
              v-for="(officer, idx) in item?.officers_and_units"
              :key="idx"
            >
              <h4>Petugas & Unit Terkait</h4>
              <p class="body-2 mt-1">{{ officer.officer_and_unit_name }}</p>
              <h4>Tindak Lanjut</h4>
              <p class="body-2 mt-1">{{ officer.follow_up }}</p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.image_urls`]="{ item }">
        <v-img
          class="my-2"
          :src="item.image_urls[0]"
          max-width="100px"
          max-height="100px"
          :style="{ 'border-radius': '10px' }"
        ></v-img>
      </template>
      <template v-slot:[`item.arrow`]="{ item }">
        <router-link :to="item.href" v-if="item.href">
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
        <router-link to="#" v-else>
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.countdown`]="{ item }">
        <span :class="countdownClass(item.countdown)">{{
          item.countdown
        }}</span>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogDownload" width="auto">
      <v-card class="px-5 py-5">
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('XLSX')"
        >Download as XLSX</v-btn
        >
        <v-btn
            class="white--text text-capitalize ml-2"
            color="#004643"
            @click="downloadAs('PDF')"
        >Download as PDF</v-btn
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import ChartComponent from "@/components/ChartComponent.vue";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    ChartComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      const response = await this.$axios.get(
        `/all-special-incidents?from=${this.dateFrom}&to=${this.dateTo}`
      );
      this.tableItems = response.data.data.special_incidents;
    },
    async downloadAs(format) {
      const queryParams = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      try {
        const endpoint = format === "XLSX" ? "/download-special-incidents" : "/download-special-incidents/as-pdf";
        const response = await this.$axios.get(endpoint, {
          params: queryParams,
        });
        const mediaType = format === "XLSX" ? "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," : "data:application/pdf;base64,";
        const base64Data = format === "XLSX" ? response.data.excelData : response.data.pdfData;

        const url = mediaType + base64Data;
        const ext = format === "XLSX" ? "xlsx" : "pdf";

        const a = document.createElement("a");
        const formattedDate1 = `${this.dateFrom.slice(
          9,
          11
        )}${this.dateFrom.slice(5, 7)}${this.dateFrom.slice(0, 4)}`;
        const formattedDate2 = `${this.dateTo.slice(9, 11)}${this.dateTo.slice(
          5,
          7
        )}${this.dateTo.slice(0, 4)}`;
        const fileName = `Kejadian Khusus_${formattedDate1}_${formattedDate2}.${ext}`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    openDialogDownload() {
      this.dialogDownload = true;
    }
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((tableItem) => ({
        ...tableItem,
        href: `/lalu-lintas/kejadian-khusus/${tableItem.id}`,
        dialog: false,
      }));
    },
  },
  data: () => ({
    dateFrom: null,
    dateTo: null,
    snackbar: false,
    message: "",
    timeout: 3000,
    dialog: false,
    search: "",
    title: "Kejadian Khusus",
    tableHeaders: [
      {
        text: "Waktu Kejadian",
        align: "start",
        value: "incident_time",
      },
      { text: "Lokasi Kejadian", value: "incident_location" },
      { text: "Tipe Kejadian", value: "special_incident_type" },
      { text: "Uraian Kejadian", value: "incident_description" },
      { text: "Cuaca", value: "condition_name" },
      { text: "Penanganan Petugas & Unit", value: "officers_and_units" },
      { text: "Foto", value: "image_urls", sortable: false, filterable: false },
      {
        text: "",
        value: "arrow",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      },
    ],
    tableItems: [],
    crumbs: [
      {
        text: "Layanan Lalu Lintas",
        disabled: false,
        href: "/lalu-lintas",
      },
      {
        text: "Kejadian Khusus",
        disabled: true,
        href: "/lalu-lintas/kejadian-khusus",
      },
    ],
    dialogDownload: false,
  }),
};
</script>

<style></style>
