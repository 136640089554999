<template>
  <v-breadcrumbs :items="crumbs" class="px-0 pb-0"></v-breadcrumbs>
</template>

<script>
export default {
  props: {
    crumbs: Array,
  },
};
</script>

<style></style>
