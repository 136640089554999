<template>
  <div class="card-container px-8 py-8">
    <div v-if="countDownTime">
      <h6>Sisa Waktu Penyelesaian</h6>
      <div
        v-if="!countDownTime.includes(NaN)"
        style="display: flex; flex-direction: row"
        class="align-center"
      >
        <div class="time-data" :class="countdownClass(countDownTime)">
          {{ getTime(countDownTime)[0] }}
        </div>
        <div class="time-data mx-5" :class="countdownClass(countDownTime)">
          :
        </div>
        <div class="time-data" :class="countdownClass(countDownTime)">
          {{ getTime(countDownTime)[1] }}
        </div>
        <div class="time-data mx-5" :class="countdownClass(countDownTime)">
          :
        </div>
        <div class="time-data" :class="countdownClass(countDownTime)">
          {{ getTime(countDownTime)[2] }}
        </div>
      </div>
      <div
        v-else
        style="display: flex; flex-direction: row"
        class="align-center"
      >
        <p>-</p>
      </div>
    </div>
    <div v-if="completedTime">
      <h6>Lama Waktu Penyelesaian</h6>
      <div style="display: flex; flex-direction: row" class="align-center">
        <div class="time-data">{{ getTime(completedTime)[0] }}</div>
        <div class="time-data mx-5">:</div>
        <div class="time-data">{{ getTime(completedTime)[1] }}</div>
        <div class="time-data mx-5">:</div>
        <div class="time-data">{{ getTime(completedTime)[2] }}</div>
      </div>
    </div>
    <div v-if="initialReportTime !== undefined || initialReportTime != null">
      <h6>Waktu Laporan</h6>
      <p>{{ initialReportTime }}</p>
    </div>
    <div v-if="followUpReportTime !== undefined || followUpReportTime != null">
      <h6>Waktu Tindak Lanjut</h6>
      <p>{{ followUpReportTime }}</p>
    </div>
    <div v-if="finalReportTime !== undefined || finalReportTime != null">
      <h6>Waktu Selesai Laporan</h6>
      <p>{{ finalReportTime }}</p>
    </div>
    <div v-if="status !== undefined || status != null">
      <h6>Status</h6>
      <p>{{ status }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "initialReportTime",
    "followUpReportTime",
    "finalReportTime",
    "status",
    "countDownTime",
    "completedTime",
  ],
  methods: {
    getTime(countdown) {
      let lst = countdown.split(":");
      return lst;
    },
    countdownClass(countdown) {
      if (countdown.includes("-") && /\d/.test(countdown)) {
        return "red--text";
      } else if (/\d/.test(countdown)) {
        return "yellow--text";
      } else {
        return "black--text";
      }
    },
  },
};
</script>

<style>
.card-container {
  box-shadow: 0px 2px 6px #e8e4e6;
  border-radius: 20px;
  height: 100%;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 20px;
  font-weight: 500;
  word-wrap: break-word;
}

.time-data {
  font-size: 48px;
  font-weight: 500;
}
</style>
