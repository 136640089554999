<template>
  <v-row align="center" style="font-size: 14px">
    <v-col cols="auto">
      <v-layout> Pilih Tanggal</v-layout>
    </v-col>

    <v-col cols="auto">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="text-align: center !important"
            v-model="date"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" @input="menu = false" @change="callParentFunction"></v-date-picker>
      </v-menu>
    </v-col>
    s.d.
    <v-col cols="auto">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date2"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date2" @input="menu2 = false" @change="callParentFunction"></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  beforeMount() {
    this.date = this.dateFrom
    this.date2 = this.dateTo
  },
  data: () => ({
    date: null,
    date2: null,
    menu: false,
    menu2: false,
  }),
  props: {
    dateFrom: String,
    dateTo: String,
    func: Function
  },
  methods: {
    callParentFunction() {
      this.func(this.date, this.date2)
    }
  }
};
</script>

<style></style>
