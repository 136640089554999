<template>
  <div>
    <h5>
      {{ name }}
      <span class="caption ml-2" v-if="!isWithoutAvail">{{ desc }}</span>
    </h5>
    <v-row>
      <v-col cols="6" class="pb-0" v-if="!isWithoutAvail">
        <h5 class="font-weight-medium">Ketersediaan</h5>
        <p class="body-2 mt-1 mb-0">{{ avail || "-" }}</p>
      </v-col>
      <v-col cols="6" class="pb-0">
        <h5 class="font-weight-medium">Kondisi</h5>
        <p class="body-2 mt-1 mb-0">{{ condition || "-" }}</p>
      </v-col>
      <v-col cols="12" class="pt-1">
        <h5 class="font-weight-medium">Keterangan Tambahan (opsional)</h5>
        <p class="body-2 mt-1">{{ info || "-" }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    desc: String,
    avail: String,
    condition: String,
    info: String,
    isWithoutAvail: {
      default: false,
    },
  },
};
</script>

<style></style>
