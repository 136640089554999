<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component title="Profil Saya" />
    <v-row>
      <v-col cols="2"
        ><v-container style="width: 150px; height: 150px; margin: 20px auto">
            <v-img
              style="object-fit: cover; width: 100%; height: 100%; border-radius: 50%"
              :src="
                account.image
                  ? account.image
                  : require('@/assets/default_profile.png')
              "
            ></v-img>
        </v-container>
      </v-col>

      <v-col>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Nama</p>
          <h4>{{ account.fullname }}</h4>
        </div>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Pekerjaan</p>
          <h4>{{ account.role || "-" }}</h4>
        </div>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Tempat Tanggal Lahir</p>
          <h4>{{ account.ttl != "None, None" ? account.ttl || "-" : "-" }}</h4>
        </div>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Telepon</p>
          <h4>{{ account.phone || "-" }}</h4>
        </div>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Email</p>
          <h4>{{ account.email || "-" }}</h4>
        </div>
        <div class="mb-5">
          <p class="subtitle-1 my-1">Alamat</p>
          <h4>{{ account.address || "-" }}</h4>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click="openDialogEditProfile">Ubah Profile</v-btn>
        <v-btn class="ml-4" @click="dialogChangePass = true">Ubah Password</v-btn>
      </v-col>
    </v-row>

    <!--  POP UP CHANGE PASSWORD  -->
    <v-dialog v-model="dialogChangePass" width="auto">
      <v-card class="px-10 py-10">
        <v-text-field
          required
          v-model="changePassData.oldPassword"
          :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPass ? 'text' : 'password'"
          name="input-10-2"
          label="Password Lama"
          class="input-group--focused"
          @click:append="showOldPass = !showOldPass"
        ></v-text-field>
        <v-text-field
            required
            v-model="changePassData.newPassword"
            :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPass ? 'text' : 'password'"
            name="input-10-2"
            label="Password Baru"
            class="input-group--focused"
            @click:append="showNewPass = !showNewPass"
        ></v-text-field>
        <v-text-field
            required
            v-model="changePassData.confirmationPassword"
            :append-icon="showConfPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfPass ? 'text' : 'password'"
            name="input-10-2"
            label="Konfirmasi Password Baru"
            class="input-group--focused"
            @click:append="showConfPass = !showConfPass"
        ></v-text-field>
        <v-row class="mt-5">
          <v-btn class="white--text text-capitalize" color="#004643" @click="changePassword">Ubah Password</v-btn>
          <v-btn class="ml-4" color="#004643" outlined @click="dialogChangePass = false">Kembali</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <!--  POP UP EDIT PROFILE  -->
    <v-dialog v-model="dialogEditProfile" width="auto">
      <v-card class="px-10 py-10">
        <v-text-field
            required
            v-model="editProfileData.fullname"
            label="Nama Lengkap"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="editProfileData.birthplace"
            label="Tempat Lahir"
            class="mb-2"
        ></v-text-field>

        <label>Tanggal Lahir</label>
        <v-menu
            ref="menu"
            v-model="showDatePicker"
            :close-on-content-click="false"
            :return-value.sync="editProfileData.birthdate"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="editProfileData.birthdate"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="editProfileData.birthdate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDatePicker = false">
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(editProfileData.birthdate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
            required
            v-model="editProfileData.email"
            label="Email"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="editProfileData.address"
            label="Alamat"
            class="mb-2"
        ></v-text-field>

        <v-text-field
            required
            v-model="editProfileData.phone"
            label="No. Telpon"
            class="mb-2"
        ></v-text-field>

        <div class="mx-3 my-2 py-2">
          <label>Foto Profil</label>
          <label class="ml-5" id="label-image" for="file-upload">+</label>
          <input
              type="file"
              id="file-upload"
              multiple
              @change="handleFileUpload"
              accept="image/*"
          />
          <div class="image-container">
            <div v-if="imagePicked !== null" class="image-item">
              <div style="width: 150px; height: 150px">
                <img
                    :src="imagePicked.previewUrl"
                    alt="Preview"
                    class="pic"
                    style="object-fit: cover; width: 100%; height: 100%; border-radius: 50%"
                />
              </div>
              <button
                  class="remove-button"
                  @click="removeImage()"
              >
                X
              </button>
            </div>
          </div>
        </div>

        <v-row class="mt-5">
          <v-btn class="white--text text-capitalize" color="#004643" @click="submitEditProfile">Ubah Profil</v-btn>
          <v-btn class="ml-4" color="#004643" outlined @click="dialogEditProfile = false">Kembali</v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import Cookies from "js-cookie";
import * as constant from "@/constants/constant";

export default {
  components: { TitleComponent },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      if (Cookies.get("loginResponse") == undefined)
        window.location.replace("/login");
      this.account = JSON.parse(Cookies.get("loginResponse"));
    },
    async changePassword() {
      if (this.changePassData.oldPassword == "") {
        this.snackbar = true;
        this.message = "Password lama harus diisi";
        return;
      }

      if (this.changePassData.newPassword == "") {
        this.snackbar = true;
        this.message = "Password baru harus diisi";
        return;
      }

      if (this.changePassData.confirmationPassword == "") {
        this.snackbar = true;
        this.message = "Konfirmasi password harus diisi";
        return;
      }

      await this.$axios.patch(
          constant.BASE_URL + "/change-password/" + this.account.id,
          this.changePassData
      ).then((resp) => {
        if (resp.data.status == 'Ok') {
          this.message = "Password berhasil diubah"
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.dialogChangePass = false;
      });
    },
    openDialogEditProfile() {
      this.editProfileData = {
        fullname: this.account.fullname,
        birthplace: this.account.ttl.split(", ")[0],
        birthdate: this.account.ttl.split(", ")[1],
        email: this.account.email,
        address: this.account.address,
        phone: this.account.phone,
        image_url: this.account.image,
        role: this.account.role,
      }

      if (this.account.image !== null) {
        this.imagePicked = {
          previewUrl: this.account.image,
          file: null
        }
      }

      this.dialogEditProfile = true;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePicked = {
          file: file,
          previewUrl: e.target.result,
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.imagePicked = null;
    },
    async submitEditProfile() {
      if (this.imagePicked !== null) {
        const formData = new FormData();
        formData.append("image", this.imagePicked.file);
        const response = await this.$axios.post("/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.editProfileData.image_url = response.data.url;
      }

      await this.$axios.put("/users/" + this.account.id, this.editProfileData).then((resp) => {
        if (resp.data.status === 'Ok') {
          this.account.fullname = this.editProfileData.fullname;
          this.account.ttl = `${this.editProfileData.birthplace}, ${this.editProfileData.birthdate}`;
          this.account.email = this.editProfileData.email;
          this.account.address = this.editProfileData.address;
          this.account.phone = this.editProfileData.phone;
          this.account.image = this.editProfileData.image_url;
          Cookies.set("loginResponse", JSON.stringify(this.account));
          this.message = "Berhasil mengubah profile";
        } else {
          this.message = resp.data.status;
        }
        this.snackbar = true;
        this.dialogLoading = false;
        this.dialogEditProfile = false;
      })
    }
  },
  computed: {},
  data: () => ({
    account: {},

    // CHANGE PASSWORD
    dialogChangePass: false,
    changePassData: {
      oldPassword: "",
      newPassword: "",
      confirmationPassword: ""
    },
    showOldPass: false,
    showNewPass: false,
    showConfPass: false,

    // EDIT PROFILE
    dialogEditProfile: false,
    imagePicked: null,
    editProfileData: {
      fullname: "",
      birthplace: "",
      birthdate: "",
      email: "",
      address: "",
      phone: "",
      image_url: "",
      role: ""
    },
    showDatePicker: false,

    snackbar: false,
    timeout: 3000,
    message: "",
  }),
};
</script>

<style>
  .pic {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
</style>
