<template>
  <v-navigation-drawer color="#ABD1C6" permanent app width="60px">
    <v-list nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :href="item.href"
        :ripple="false"
        :disabled="item.disabled"
      >
        <v-list-item-icon>
          <v-img
            :src="require(`@/assets/${item.disabled ? item.iconDisabled : item.icon}`)"
          ></v-img>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list nav>
        <v-list-item link href="https://wa.me/6282299225018" :ripple="false">
          <v-list-item-icon>
            <v-img :src="require(`@/assets/icons/contact.svg`)"></v-img>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link href="/profile" :ripple="false">
          <div style="width: 30px; height: 30px">
            <v-img
                style="object-fit: cover; width: 100%; height: 100%; border-radius: 50%"
                :src="
              account.image
                ? account.image
                : require('@/assets/default_profile.png')
            "
            ></v-img>
          </div>
        </v-list-item>
        <v-list-item link @click="logout" :ripple="false">
          <v-list-item-icon>
            <v-icon color="#004643">mdi-logout</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import Cookies from "js-cookie";
import * as constant from "@/constants/constant";

export default {
  beforeMount() {
    this.init();
  },
  data: () => ({
    items: [],
    account: {
      image: null,
    },
  }),
  methods: {
    async init() {
      this.account = JSON.parse(Cookies.get("loginResponse"));
      this.items = [
        {
          title: "dashboard",
          icon: "logo.png",
          href: "/",
          disabled: false
        },
        {
          title: "account",
          icon: "icons/account-credit-card.svg",
          iconDisabled: "icons/account-credit-card-disabled.svg",
          href: "/layanan-transaksi",
          disabled: !this.account.scopes.includes(constant.SCOPES.view_transaction)
        },
        {
          title: "traffic",
          icon: "icons/traffic-light.svg",
          iconDisabled: "icons/traffic-light-disabled.svg",
          href: "/lalu-lintas",
          disabled: !this.account.scopes.includes(constant.SCOPES.accident) &&
              !this.account.scopes.includes(constant.SCOPES.asset_disruption) &&
              !this.account.scopes.includes(constant.SCOPES.traffic_disturbance) &&
              !this.account.scopes.includes(constant.SCOPES.special_incident)
        },
        {
          title: "maintenance",
          icon: "icons/wrench-clock.svg",
          iconDisabled: "icons/wrench-clock-disabled.svg",
          href: "/layanan-pemeliharaan",
          disabled: !this.account.scopes.includes(constant.SCOPES.maintenance)
        },
        {
          title: "asset",
          icon: "icons/layanan-aset.svg",
          iconDisabled: "icons/layanan-aset-disabled.svg",
          href: "/aset",
          disabled: !this.account.scopes.includes(constant.SCOPES.view_asset) &&
              !this.account.scopes.includes(constant.SCOPES.manage_asset) &&
              !this.account.scopes.includes(constant.SCOPES.delete_asset)
        },
      ]

      if (this.account.scopes.includes(constant.SCOPES.super)) {
        this.items.push(
            {
              title: "admin",
              icon: "icons/admin-icon.svg",
              iconDisabled: "icons/admin-icon-disabled.svg",
              href: "/admin",
              disabled: !this.account.scopes.includes(constant.SCOPES.super)
            }
        );
      }
    },
    logout() {
      Cookies.remove("loginResponse");
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
