<template>
  <a
    class="navigationCard"
    :href="navigation"
    :style="disabled ? 'cursor: not-allowed' : ''"
  >
    <v-icon :color="disabled ? 'grey' : '#004643'" x-large class="ml-5">
      {{ icons }}
    </v-icon>
    <div :class="disabled ? 'disabledCard' : 'enabledCard'">{{ title }}</div>
  </a>
</template>

<script>
export default {
  name: "NavigationCard",
  props: ["icons", "title", "navigation", "disabled"],
};
</script>

<style scoped>
.vertical-center {
  text-align: center; /* Horizontally center the text within the div */
}
.navigationCard {
  padding: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  font-size: 20px;
  margin: auto;
  border-radius: 20px;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 110px;
  align-items: center;
}

.disabledCard {
  color: grey;
  margin-left: 30px;
  display: block;
}

.enabledCard {
  color: #004643;
  margin-left: 30px;
  display: block;
}
</style>
