<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <p class="my-5">
      Berisikan daftar aset yang menjadi temuan saat inspeksi dilakukan yang
      harus di kategorisasi.
    </p>
    <v-row>
      <v-col cols="6">
        <card-image-component
          title="Jumlah Temuan"
          :number="number_of_findings"
          imageName="finding-1.svg"
        />
      </v-col>
      <v-col cols="6">
        <card-image-component
          title="Jumlah Aset Diperbaiki"
          :number="number_of_fixed_assets"
          imageName="fixing-asset.svg"
        />
      </v-col>
    </v-row>

    <date-filter-component
      :date-from="date1"
      :date-to="date2"
      :func="getData"
    />

    <table-component :headers="tableHeaders" :items="computedInitialReports">
    </table-component>
  </v-container>
</template>

<script>
import * as constant from "@/constants/constant";
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DateFilterComponent from "@/components/DateFilterComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import CardImageComponent from "@/components/CardImageComponent.vue";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
    TableComponent,
    CardImageComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      await this.getData();
    },
    async getData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date1 = dateFrom;
        this.date2 = dateTo;
      }
      const params = {
        from: this.date1,
        to: this.date2,
        is_recategorized: "false",
        source: "maintenance",
      };
      let response;
      response = await this.$axios.get("/all-initial-reports", { params });
      this.initialReports = response.data.data.initial_reports;
      this.startCountdown();
      response = await this.$axios.get("/stat/asset-maintenance");
      this.number_of_findings = response.data.data.number_of_findings;
      this.number_of_fixed_assets = response.data.data.number_of_fixed_assets;
    },
    startCountdown() {
      this.initialReports.forEach((initialReport) => {
        if (initialReport.asset_category_id != 3) {
          this.$set(this.countdowns, initialReport.id, "-");
          return;
        }
        const createdAt = new Date(initialReport.created_at);
        let deadline;
        if (![6, 7, 8].includes(initialReport.asset_sub_category_id)) {
          deadline =
            constant.deadlines[initialReport.asset_sub_category_id] * 3600;
        } else {
          deadline =
            constant.deadlines[initialReport.asset_advance_category_id] * 3600;
        }
        setInterval(() => {
          const currentTime = new Date();
          const targetTime = new Date(createdAt.getTime() + deadline * 1000);
          const timeDiff = targetTime - currentTime;

          if (timeDiff < 0) {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000) * -1;
            const seconds = Math.floor((timeDiff % 60000) / 1000) * -1;

            this.$set(
              this.countdowns,
              initialReport.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          } else {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000);
            const seconds = Math.floor((timeDiff % 60000) / 1000);

            this.$set(
              this.countdowns,
              initialReport.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          }
        }, 1000);
      });
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
  },
  computed: {
    computedInitialReports() {
      return this.initialReports.map((initialReport) => ({
        ...initialReport,
        asset_category:
          constant.assetCategories[initialReport.asset_category_id],
        asset_sub_category:
          constant.assetSubCategories[initialReport.asset_sub_category_id] ||
          "-",
        countdown: this.countdowns[initialReport.id] || "00:00:00",
        href: "/aset/pemeliharaan-aset/inisiasi-laporan/" + initialReport.id,
      }));
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      date1: initialDate.date_start,
      date2: initialDate.date_end,
      title: "List Pemeliharaan Aset",
      tableHeaders: [
        {
          text: "Kategori",
          align: "start",
          value: "asset_category",
        },
        { text: "Sub Kategori", value: "asset_sub_category" },
        { text: "Lokasi", value: "location" },
        { text: "Deskripsi Kondisi Aset", value: "condition" },
        { text: "Pelapor", value: "reporter" },
        { text: "Waktu Laporan", value: "created_at" },
        { text: "Waktu Penyelesaian", value: "countdown" },
        {
          text: "Foto",
          value: "image_url",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      initialReports: [],
      crumbs: [
        {
          text: "Layanan Aset",
          disabled: false,
          href: "/aset",
        },
        {
          text: "List Pemeliharaan Aset",
          disabled: true,
          href: "/aset/pemeliharaan-aset",
        },
      ],
      countdowns: {},
      number_of_fixed_assets: 0,
      number_of_findings: 0,
    };
  },
};
</script>

<style></style>
