import { render, staticRenderFns } from "./AssetDashboardPage.vue?vue&type=template&id=46011335"
import script from "./AssetDashboardPage.vue?vue&type=script&setup=true&lang=js"
export * from "./AssetDashboardPage.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AssetDashboardPage.vue?vue&type=style&index=0&id=46011335&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports