<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <v-card class="pa-10 mt-4" :color="'#EDF6F4'">
      <main-table-component
        :headers="tableHeaders2"
        :items="computedInitialReports"
        :hideFooter="true"
      />
    </v-card>

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10">
      Re-kategorisasi
    </h1>
    <v-row class="my-3" style="font-size: 0.875rem; font-weight: 400">
      <v-col cols="6"
        >Pilih Kategori Utama<v-select
          :items="items"
          label="Pilih"
          v-on:change="selectCategory"
        ></v-select
      ></v-col>
      <v-col cols="6"
        >Cari Nama Aset<v-text-field
          v-model="search"
          label="Cari"
          append-icon="mdi-magnify"
        />
      </v-col>
    </v-row>
    <recategorization-table-component-vue
      tableTitle="Re-kategorisasi"
      :headers="tableHeaders"
      :items="assetTableItems"
      :slotName="`item.id`"
      :initialReportId="this.$route.params.id"
      :source="constant.sources[initialReports[0].source]"
      :search="search"
      redirect="Laporan Aset"
    />
  </v-container>
</template>

<script>
import * as constant from "@/constants/constant";
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import MainTableComponent from "@/components/MainTableComponent.vue";
import RecategorizationTableComponentVue from "@/components/RecategorizationTableComponent.vue";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    MainTableComponent,
    RecategorizationTableComponentVue,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      let response = await this.$axios.get(
        `/initial-report/${this.$route.params.id}`
      );
      this.initialReports = [response.data.data];

      response = await this.$axios.get(`/all-assets`);
      this.assets = response.data.data.assets;
    },
    selectCategory(ctg) {
      if (ctg === "Semua Kategori") {
        this.category = -1;
        return;
      }

      this.category = parseInt(
        Object.keys(constant.assetCategories)[
          Object.values(constant.assetCategories).indexOf(ctg)
        ]
      );
    },
  },
  computed: {
    assetTableItems() {
      let assetWithKeyword = this.assets;

      if (this.category === -1) {
        return assetWithKeyword;
      }
      return assetWithKeyword.filter(
        (asset) => asset.category_id === this.category
      );
    },
    computedInitialReports() {
      return this.initialReports.map((initialReport) => ({
        ...initialReport,
        asset_category:
          constant.assetCategories[initialReport.asset_category_id],
        asset_sub_category:
          constant.assetSubCategories[initialReport.asset_sub_category_id] ||
          "-",
        asset_advance_category:
          constant.assetAdvanceCategories[
            initialReport.asset_advance_category_id
          ] || "-",
      }));
    },
  },
  data: () => ({
    constant: constant,
    search: "",
    category: -1,
    items: ["Semua Kategori"].concat(Object.values(constant.assetCategories)),
    assets: [],
    title: "Kecelakaan - Gangguan Aset",
    tableHeaders: [
      {
        text: "Nama Aset",
        align: "start",
        value: "asset_name",
        sortable: false,
      },
      { text: "Kode Aset", value: "asset_code", sortable: false },
      { text: "Lokasi Aset", value: "asset_location", sortable: false },
      { text: "Koordinat", value: "coordinate", sortable: false },
      { text: "Pemilik", value: "asset_owner", sortable: false },
      { text: "Pengembang", value: "asset_developer", sortable: false },
      { text: "Operator", value: "asset_operator", sortable: false },
      { text: "Keterangan Tambahan", value: "information", sortable: false },
      {
        text: "",
        value: "pilih",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      },
    ],
    tableHeaders2: [
      {
        text: "Kategori Utama",
        value: "asset_category",
        align: "start",
        sortable: false,
      },
      {
        text: "Sub Kategori",
        value: "asset_sub_category",
        align: "start",
        sortable: false,
      },
      {
        text: "Kategori Lanjutan",
        value: "asset_advance_category",
        align: "start",
        sortable: false,
      },
      {
        text: "Lokasi",
        value: "location",
        align: "start",
        sortable: false,
      },
      {
        text: "Deskripsi Kondisi Aset",
        value: "desc_initial",
        align: "start",
        sortable: false,
      },
      {
        text: "Foto",
        value: "images",
        sortable: false,
        filterable: false,
      },
    ],
    tableItems2: [],
    crumbs: [
      {
        text: "Layanan Aset",
        disabled: false,
        href: "/",
      },
      {
        text: "List Laporan Aset",
        disabled: false,
        href: "/aset/laporan-aset",
      },
      {
        text: "Kecelakaan - Gangguan Aset",
        disabled: true,
        href: "/aset/kecelakaan-gangguan-aset",
      },
    ],
    initialReports: [],
  }),
};
</script>

<style></style>
