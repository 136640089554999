<template>
  <v-container class="py-5" style="max-width: 90vw">
    <v-snackbar v-model="snackbar" :timeout="2500" top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="justify-space-between align-center" style="display: flex">
      <TitleComponent title="Layanan Transaksi"/>
      <div>
        <!--        <v-btn-->
        <!--            class="text-capitalize mr-2"-->
        <!--            color="#004643"-->
        <!--            outlined-->
        <!--            href="/layanan-transaksi/atur-tarif"-->
        <!--            :disabled="!this.account.scopes.includes(this.scopeConstant.manage_transaction)"-->
        <!--        >-->
        <!--          Atur Tarif-->
        <!--        </v-btn>-->
        <v-btn
            @click="onUpload('incomes')"
            class="white--text text-capitalize mr-2"
            color="#004643"
            :disabled="!this.account.scopes.includes(this.scopeConstant.manage_transaction)"
        >
          Update Pendapatan
        </v-btn
        >
        <v-btn
            @click="onUpload('lhr')"
            class="white--text text-capitalize mr-2"
            color="#004643"
            :disabled="!this.account.scopes.includes(this.scopeConstant.manage_transaction)"
        >
          Update Volume Lalu Lintas Harian
        </v-btn
        >
        <v-btn
            @click="onUpload('lhr_weighed')"
            class="white--text text-capitalize"
            color="#004643"
            :disabled="!this.account.scopes.includes(this.scopeConstant.manage_transaction)"
        >
          Update Volume Lalu Lintas Harian Tertimbang
        </v-btn
        >
      </div>
    </div>
    <div class="justify-space-between align-center" style="display: flex">
      <BreadcrumbsComponent :crumbs="crumbs"/>
      <p style="font-size: 12px; font-weight: 400">
        Terakhir diupdate: {{ formatDate(last_update) }}
      </p>
    </div>

    <v-dialog v-model="dialog" width="auto">
      <v-card class="px-10 py-10">
        <v-layout row wrap justify-space-between align-center>
          <h1 style="font-size: 1.5rem; font-weight: 700">Update
            <span v-if="modalType === 'lhr'">LHR</span>
            <span v-else-if="modalType ==='lhr_weighed'">LHR Tertimbang</span>
            <span v-else>Pendapatan</span>
          </h1>
          <img
              style="cursor: pointer"
              @click="dialog = false"
              :src="require('../assets/icons/ph_x-bold.svg')"
          />
        </v-layout>
        <div
            class="dropzone-container mt-10"
            @dragover.prevent="dragover"
            @dragleave.prevent="dragleave"
            @drop.prevent="drop"
            ref="dropzone"
        >
          <input
              type="file"
              name="file"
              id="fileInput"
              class="hidden-input"
              @change="onChange"
              ref="fileInput"
              accept=".xlsx"
          />

          <label
              for="fileInput"
              class="file-label"
              style="color: #abb5be; font-size: 16px; width: 50vw"
          >
            <div v-if="isDragging">Drag file disini</div>
            <div
                v-else-if="!selectedFile"
                style="display: flex; flex-direction: column"
                class="justify-center align-center"
            >
              <img
                  :src="require('../assets/icons/material-symbols_folder.svg')"
                  style="width: 32px; height: auto"
                  class="mb-1"
              />
              <div style="color: #abb5be; font-size: 16px">
                <u style="color: #004643; font-size: 16px">Cari</u> atau drag
                file untuk update
                <span v-if="modalType === 'lhr'">LHR</span>
                <span v-else-if="modalType === 'lhr_weighed'">LHR Tertimbang</span>
                <span v-else>Pendapatan</span>
                disini
              </div>
            </div>
            <div class="preview-container flex-column" v-if="selectedFile">
              <div
                  class="preview-card flex-row justify-center align-center mx-auto"
              >
                <span>{{ selectedFile.name }}</span>
                <v-btn icon @click.prevent="clearSelectedFile">
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-btn
                  color="green"
                  class="ma-2 white--text mx-auto"
                  @click="uploadFile"
              >
                Upload
                <v-icon right dark> mdi-cloud-upload</v-icon>
              </v-btn>
            </div>
          </label>
        </div>
      </v-card>
    </v-dialog>
    <DateFilterComponent
        :date-from="date_start"
        :date-to="date_end"
        :func="recallData"
    />

    <hr/>
    <transaction-data-component
        :dateStart="date_start"
        :dateEnd="date_end"
        title="Pendapatan"
        stat-url="/get-data-incomes"
        download-url="/download-incomes"
        download-name="Pendapatan"
        url-chart="/chart-incomes"
    />
    <hr/>
    <transaction-data-component
        :dateStart="date_start"
        :dateEnd="date_end"
        title="Volume Lalu Lintas Harian"
        stat-url="/get-data-lhr"
        download-url="/download-lhr"
        download-name="LHR"
        url-chart="/chart-lhr"
    />
    <hr/>
    <transaction-data-component
        :dateStart="date_start"
        :dateEnd="date_end"
        title="Volume Lalu Lintas Harian Tertimbang"
        stat-url="/get-data-lhr-weighed"
        download-url="/download-lhr-weighed"
        download-name="LHR Tertimbang"
        url-chart="/chart-lhr-weighed"
    />
    <!--    <hr />-->
    <!--    <transaction-data-component-->
    <!--      :dateStart="date_start"-->
    <!--      :dateEnd="date_end"-->
    <!--      title="Kecepatan Transaksi"-->
    <!--      stat-url="/stat-transaction_speeds"-->
    <!--      download-url="/download-list-transaction-speeds"-->
    <!--      download-name="Kecepatan Transaksi"-->
    <!--      url-chart="/chart-transaction-speed"-->
    <!--    />-->
    <!--    <hr />-->
    <!--    <transaction-data-component-->
    <!--      :dateStart="date_start"-->
    <!--      :dateEnd="date_end"-->
    <!--      title="Jumlah Antrian"-->
    <!--      stat-url="/all-queues"-->
    <!--      download-url="/download-list-queues"-->
    <!--      download-name="Jumlah Antrian"-->
    <!--      url-chart="/chart-lhr"-->
    <!--    />-->
  </v-container>
</template>

<script>
import DateFilterComponent from "@/components/DateFilterComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TitleComponent from "@/components/TitleComponent";
import TransactionDataComponent from "@/components/TransactionDataComponent.vue";
import Cookies from "js-cookie";
import * as constant from "../constants/constant.js";

export default {
  components: {
    DateFilterComponent,
    BreadcrumbsComponent,
    TitleComponent,
    TransactionDataComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      if (Cookies.get("loginResponse") == undefined)
        window.location.replace("/login");
      this.account = JSON.parse(Cookies.get("loginResponse"));
      const response = await this.$axios.get("/latest-update-tariff");
      this.last_update = response.data.data[0].created_at;
    },
    onChange() {
      const inputElement = this.$refs.fileInput;
      if (inputElement.files.length > 0) {
        this.selectedFile = inputElement.files[0];
      }
    },
    formatDate(inputDateString) {
      const inputDate = new Date(inputDateString);

      const day = inputDate.getDate().toString().padStart(2, "0");
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const year = inputDate.getFullYear();
      const outputDateString = `${day}/${month}/${year}`;

      return outputDateString;
    },
    onUpload(type) {
      this.dialog = true
      this.modalType = type
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append("excel_file", this.selectedFile);

      let url = this.modalType === 'lhr' ? '/upload-lhr' : (this.modalType === 'lhr_weighed' ? '/upload-lhr-weighed' : '/upload-incomes');
      const response = await this.$axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.selectedFile = null;
      this.message = response.data.status;
      this.snackbar = true;
      this.dialog = false;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;

      if (files.length > 0) {
        const file = files[0];
        if (file.name.endsWith(".xlsx")) {
          this.selectedFile = file;
          this.isDragging = false;
        }
      }
    },
    clearSelectedFile() {
      this.selectedFile = null;
      const inputElement = this.$refs.fileInput;
      inputElement.value = "";
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
    async recallData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date_start = dateFrom;
        this.date_end = dateTo;
      }
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      last_update: "",
      snackbar: false,
      message: "",
      selectedFile: null,
      date_start: initialDate.date_start,
      date_end: initialDate.date_end,
      modalType: "",
      crumbs: [
        {
          text: "Layanan Transaksi",
          disabled: true,
          href: "/layanan-transaksi",
        },
      ],
      isDragging: false,
      files: [],
      dialog: false,
      account: null,
      scopeConstant: constant.SCOPES
    };
  },
};
</script>

<style>
.dropzone-container {
  padding: 4rem;
  border: 2px #e8e4e6 dotted;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

.preview-container {
  display: flex;
  margin-top: 2rem;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}

.v-carousel__controls {
  background: none;
}

.mdi-circle::before {
  color: #d9d9d9;
}

.v-btn--active .mdi-circle::before {
  color: #004643;
}
</style>
