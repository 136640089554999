<template>
  <v-container class="py-5" style="max-width: 90vw">
    <h1 style="font-size: 1.5rem; font-weight: 700">Patroli Jalan Raya</h1>
    <v-breadcrumbs :items="crumbs" class="px-0 pb-0"></v-breadcrumbs>

    <chart-component
      title="Total Patroli Jalan Raya"
      class="mb-8"
      url-chart="/chart-gangguan-aset"
      :parentFunc="updateDate"
    />

    <h1 style="font-size: 1.5rem; font-weight: 500" class="mt-10">
      Data Penanganan Kendaraan Mengalami Gangguan
    </h1>

    <v-layout justify-end class="my-3">
      <v-card-title class="ma-0 py-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
        ></v-text-field>
      </v-card-title>
      <v-btn
        class="white--text text-capitalize"
        color="#004643"
        width="150"
        height="34"
        @click="download"
        >Download</v-btn
      >
    </v-layout>

    <v-data-table
      :headers="headers"
      :search="search"
      :items="computedTableItems"
      class="elevation-1"
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20],
      }"
      :hide-default-footer="false"
    >
      <template v-slot:[`item.response_time`]="{ item }">
        <v-btn
          class="text-capitalize"
          color="#004643"
          width="150"
          height="34"
          outlined
          @click.stop="item.dialog = true"
          >Detail</v-btn
        >
        <v-dialog v-model="item.dialog" width="434">
          <v-card>
            <v-card-title>
              Waktu Response
              <v-spacer></v-spacer>
              <v-btn icon @click="item.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <h4>Waktu Informasi Diterima</h4>
              <p class="body-2 mt-1">{{ item.information_accepted_time }}</p>
              <h4>Waktu Tiba di Lokasi</h4>
              <p class="body-2 mt-1">{{ item.arrival_time }}</p>
              <h4>Response Time</h4>
              <p class="body-2 mt-1">Nunggu Logic di BE</p>
              <h4>Waktu Selesai</h4>
              <p class="body-2 mt-1">{{ item.completion_time }}</p>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.image_urls`]="{ item }">
        <v-img
          class="my-2"
          :src="item.image_urls[0]"
          max-width="100px"
          max-height="100px"
          :style="{ 'border-radius': '10px' }"
        ></v-img>
      </template>
      <template v-slot:[`item.arrow`]="{ item }">
        <router-link :to="item.href" v-if="item.href">
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
        <router-link to="#" v-else>
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ChartComponent from "@/components/ChartComponent";

export default {
  components: {
    ChartComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
      }
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      const response = await this.$axios.get(`/all-pjr`, {
        params: { ...params, type: "days" },
      });
      this.tableItems = response.data.data.pjr;
    },
    async download() {
      const queryParams = {
        from: this.dateFrom,
        to: this.dateTo,
      };

      let urlDownload, fileName;
      urlDownload = `/download-list-pjr`;
      fileName = `Patroli Jalan Raya_PJR_${this.dateFrom}_${this.dateTo}.xlsx`;

      try {
        const response = await this.$axios.get(urlDownload, {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((data) => ({
        ...data,
        asset_damaged: data.asset_damaged ? "Merusak" : "Tidak Merusak",
        compensation: data.compensation ? "Ganti Rugi" : "Tidak Ada Ganti Rugi",
        href: `/lalu-lintas/patroli-jalan-raya/laporan/${data.id}`,
        location: `KM ${data.incident_kilometer ?? 0} - ${data.track} - ${
          data.lane
        }`,
        vehicle: `${data.vehicle_type} - ${data.vechicle_class} - ${data.police_number}`,
        dialog: false,
      }));
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      dateFrom: null,
      dateTo: null,
      headers: [
        {
          text: "Waktu Kejadian",
          align: "start",
          value: "incident_time",
        },
        { text: "Lokasi Kejadian", value: "location" },
        { text: "Kendaraan", value: "vehicle" },
        { text: "Uraian Kejadian", value: "incident_description" },
        { text: "Uraian Penanganan", value: "handling_measures" },
        { text: "Waktu Response", value: "response_time" },
        { text: "Foto", value: "image_urls" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
      crumbs: [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Patroli Jalan Raya",
          disabled: true,
          href: "/lalu-lintas/patroli-jalan-raya",
        },
      ],
      search: "",
      headersRc: [
        {
          text: "Tanggal Pengecekan",
          align: "start",
          value: "incident_time",
        },
        { text: "KM Awal", value: "location" },
        { text: "KM Akhir", value: "km" },
        { text: "Pencapaian", value: "type" },
        { text: "BBM", value: "handling_measures" },
        { text: "Shift", value: "handling_measures" },
        { text: "Petugas", value: "petugas" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-item--active {
  background-color: #abd1c6 !important;
}
</style>
