<template>
  <h1 style="font-size: 1.5rem; font-weight: 700">{{ title }}</h1>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style></style>
