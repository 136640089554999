<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    class="elevation-1"
    :items-per-page="20"
    :footer-props="{
      'items-per-page-options': [5, 10, 15, 20],
    }"
    :hide-default-footer="hideFooter"
  >
    <template v-if="enableDownload" v-slot:top>
      <v-btn class="my-5 white--text text-capitalize" style="margin-left: 90%" color="#004643" @click="exportToXLSX">Download</v-btn>
    </template>
    <template v-slot:[slotName]>
      <popup-component></popup-component>
    </template>
    <template v-slot:[`item.foto`]="{ item }">
      <v-img
        class="my-2"
        :src="item.foto"
        max-width="100px"
        max-height="100px"
        :style="{ 'border-radius': '10px' }"
      ></v-img>
    </template>
    <template v-slot:[`item.image_url`]="{ item }">
      <v-img
        class="my-2"
        :src="item.image_url"
        max-width="100px"
        max-height="100px"
        :style="{ 'border-radius': '10px' }"
      ></v-img>
    </template>
    <template v-slot:[`item.image_urls`]="{ item }">
      <v-img
        class="my-2"
        :src="item.image_urls?.[0]"
        max-width="100px"
        max-height="100px"
        :style="{ 'border-radius': '10px' }"
      ></v-img>
    </template>
    <template v-slot:[`item.images`]="{ item }">
      <v-img
        class="my-2"
        :src="item?.images?.[0]"
        max-width="100px"
        max-height="100px"
        :style="{ 'border-radius': '10px' }"
      ></v-img>
    </template>
    <template v-slot:[`item.arrow`]="{ item }">
      <router-link :to="item.href" v-if="item.href">
        <v-icon>mdi-chevron-right</v-icon>
      </router-link>
      <router-link to="#" v-else>
        <v-icon>mdi-chevron-right</v-icon>
      </router-link>
    </template>
    <template v-slot:[`item.countdown`]="{ item }">
      <span :class="countdownClass(item.countdown)" class="font-weight-bold">{{
        item.countdown
      }}</span>
    </template>
    <template v-slot:[`item.completedTime`]="{ item }">
      <span class="font-weight-bold" style="color: #004643">{{
        item.completedTime
      }}</span>
    </template>
    <template v-slot:[`item.pilih`]>
      <v-btn class="text-capitalize" color="#004643" height="31" outlined
        >Pilih</v-btn
      >
    </template>
    <template v-slot:[`item.price_of_acquisition`]="{ item }">
      <div v-if="item.price_of_acquisition">Rp{{ (item.price_of_acquisition).toLocaleString("id-ID") }},-</div>
    </template>
    <template v-slot:[`item.download`]="{ item }">
      <v-btn
        class="text-capitalize"
        color="#004643"
        height="31"
        outlined
        @click="item.download()"
        >Download
      </v-btn>
    </template>
    <template v-slot:[`item.scopes`]="{ item }">
      <div class="flex-column py-2">
        <div
            v-for="(scope, idx) in item.scopes"
            :key="idx"
            style="
              background-color: #a2a2a2;
              margin-top: 2px;
              padding: 1px;
              text-align: center;
              color: #ffffff;
              border-radius: 25px;
            "
        >
          {{ scope }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
          color="blue"
          height="31"
          outlined
          @click="item.editData()"
      >
        Edit Data
      </v-btn>

      <v-btn
          class="mt-1"
          color="blue"
          height="31"
          outlined
          @click="item.updateScopes()"
      >
        Edit Otorisasi
      </v-btn>

      <v-btn
          class="mt-1"
          color="red"
          height="31"
          outlined
          @click="item.deleteUser()"
      >
        Hapus Akun
      </v-btn>
    </template>

  </v-data-table>
</template>

<script>
import PopupComponent from "@/components/PopupComponent.vue";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export default {
  components: {
    PopupComponent,
  },
  methods: {
    countdownClass(countdown) {
      if (countdown.includes("-") && /\d/.test(countdown)) {
        return "red--text";
      } else if (/\d/.test(countdown)) {
        return "yellow--text";
      } else {
        return "black--text";
      }
    },
    async getImageArrayBuffer(imageUrl) {
      const response = await fetch(imageUrl);
      return await response.arrayBuffer();
    },
    async exportToXLSX() {
      // Filter items based on the current search value
      const filteredItems = this.items.filter(item => {
        return Object.values(item).some(val =>
            String(val).toLowerCase().includes(this.search.toLowerCase())
        );
      });

      // Create a new workbook and add a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');

      // Add headers
      worksheet.addRow(this.headers.map(header => header.text));

      // Add rows
      for (const item of filteredItems) {
        const row = this.headers
            .filter(header => header.value !== 'foto')
            .map(header => item[header.value]);
        worksheet.addRow(row);
      }

      console.log(filteredItems)

      // Embed images
      for (let i = 0; i < filteredItems.length; i++) {
        const item = filteredItems[i];
        const imageUrl = item.foto;

        if (imageUrl && imageUrl.startsWith('http')) {
          try {
            // const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
            const arrayBuffer = await this.getImageArrayBuffer(imageUrl);
            const image = workbook.addImage({
              buffer: arrayBuffer,
              extension: 'png', // Or use the appropriate image extension
            });

            // Calculate the position for the image
            const colIndex = this.headers.findIndex(header => header.value === 'foto');
            const cellAddress = { col: colIndex + 1, row: i + 2 };

            // Add the image to the cell
            worksheet.addImage(image, {
              tl: { col: cellAddress.col - 1, row: cellAddress.row - 1 },
              ext: { width: 100, height: 100 }, // Adjust the size as needed
            });

            // Adjust the column width and row height
            worksheet.getColumn(cellAddress.col).width = 15; // Adjust as needed
            worksheet.getRow(cellAddress.row).height = 75; // Adjust as needed
          } catch (error) {
            console.error('Error loading image:', error);
          }
        }
      }

      // Generate the XLSX file and trigger the download
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.fileName);
    }
  },
  props: {
    slotName: String,
    tableTitle: String,
    headers: Array,
    items: Array,
    search: String,
    hideFooter: {
      type: Boolean,
      default: false,
    },
    enableDownload: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: "data.xlsx"
    }
  },
};
</script>

<style></style>
