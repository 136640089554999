<template>
  <v-container class="py-5" style="max-width: 90vw">
    <h1 style="font-size: 1.5rem; font-weight: 700">
      Kecepatan Tempuh Rata-Rata
    </h1>
    <v-breadcrumbs :items="crumbs" class="px-0 pb-0"></v-breadcrumbs>

    <chart-component
      title="Kecepatan Tempuh Rata-Rata Kondisi Normal"
      class="mb-8"
      url-chart="/chart-gangguan-aset"
      :parentFunc="updateDate"
    />

    <table-component
      :headers="headers"
      :items="computedAccidents"
      tableTitle="Laporan Kecapatan Tempuh Rata-Rata"
      :downloadFunc="downloadExcel"
    />
  </v-container>
</template>

<script>
import ChartComponent from "@/components/ChartComponent";
import TableComponent from "@/components/TableComponent.vue";

export default {
  components: {
    ChartComponent,
    TableComponent,
  },
  methods: {
    async updateDate(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
      }
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      if (this.activeTab == "LJ.01") {
        if (this.activeTabLaporan == 0) {
          const response = await this.$axios.get(`/all-pd01`, {
            params: { ...params, type: "days" },
          });
          this.tableItems = response.data.data.pd01;
        } else {
          const response = await this.$axios.get(`/all-pd01`, {
            params: { ...params, type: "reports" },
          });
          this.tableItems = response.data.data.pd01;
        }
      }
    },
  },
  computed: {
    computedTableItems() {
      return this.tableItems.map((data) => ({
        ...data,
        asset_damaged: data.asset_damaged ? "Merusak" : "Tidak Merusak",
        compensation: data.compensation ? "Ganti Rugi" : "Tidak Ada Ganti Rugi",
        href: `/lalu-lintas/kecepatan-tempuh/laporan/${data.id}`,
        location: `KM ${data.kilometer ?? 0} - ${data.track} - ${data.lane}`,
      }));
    },
    computedTableItemsHarian() {
      return this.tableItems.map((data) => ({
        ...data,
        incident_time: data.incident_time.split(" ")[0],
        total: `Total Laporan: ${data.total_incident}`,
        href: `/lalu-lintas/kecepatan-tempuh/harian/${data.id}`,
      }));
    },
  },
  data() {
    return {
      activeTab: "LJ.01",
      activeTabLaporan: 0,
      dateFrom: null,
      dateTo: null,
      headersHarian: [
        {
          text: "Waktu Kejadian",
          align: "start",
          value: "incident_time",
        },
        { text: "Total Laporan", value: "total" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      headers: [
        {
          text: "Waktu Observasi",
          align: "start",
          value: "incident_time",
        },
        { text: "Lokasi Observasi", value: "location" },
        { text: "KM Awal - KM Akhir", value: "type" },
        { text: "Pencapaian", value: "asset_damaged" },
        { text: "Patroli", value: "compensation" },
        { text: "Keterangan Tambahan", value: "compensation" },
        { text: "Waktu", value: "compensation" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableItems: [],
      crumbs: [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Kecepatan Tempuh Rata-Rata",
          disabled: true,
          href: "/lalu-lintas/kecepatan-tempuh",
        },
      ],
      search: "",
      headersRc: [
        {
          text: "Tanggal Pengecekan",
          align: "start",
          value: "incident_time",
        },
        { text: "KM Awal", value: "location" },
        { text: "KM Akhir", value: "km" },
        { text: "Pencapaian", value: "type" },
        { text: "BBM", value: "handling_measures" },
        { text: "Shift", value: "handling_measures" },
        { text: "Petugas", value: "petugas" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-item--active {
  background-color: #abd1c6 !important;
}
</style>
