<template>
  <v-dialog v-model="dialog" width="434">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-capitalize"
        color="#004643"
        width="150"
        height="34"
        outlined
        v-bind="attrs"
        v-on="on"
        >{{ text }}</v-btn
      >
    </template>

    <slot :closeDialog="closeDialog" />
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    text: String,
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
