<script setup>
import CardIconComponent from "@/components/CardIconComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import TitleComponent from "@/components/TitleComponent";
import ChartComponent from "@/components/ChartComponent.vue";
</script>

<template>
  <v-container class="py-5" style="max-width: 90vw">
    <TitleComponent title="Layanan Lalu Lintas" />
    <BreadcrumbsComponent :crumbs="crumbs" />
    <chart-component
      title="Total Kejadian"
      class="mb-8"
      url-chart="/chart-lalu-lintas"
      :parent-func="updateDate"
    />

    <h1 style="font-size: 1.5rem; font-weight: 700" class="mb-6">Kategori</h1>
    <v-layout>
      <v-row>
        <CardIconComponent
          v-for="item in firstRowCards"
          :key="item.title"
          :title="item.title"
          :image="item.image"
          :card-size="item.cardSize"
          :navigation="item.navigation"
          :disabled="item.disabled"
        />
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <CardIconComponent
          title="Kendaraan Rescue"
          image="rescue.svg"
          navigation="/lalu-lintas/rescue"
          :disabled="true"
        />
        <CardIconComponent
          title="Ambulans"
          image="ambulance.svg"
          navigation="/lalu-lintas/ambulans"
          :disabled="true"
        />
        <CardIconComponent
          title="Patroli Jalan Tol"
          image="patroli.svg"
          navigation="/lalu-lintas/patroli-jalan-tol"
          :disabled="true"
        />
        <CardIconComponent
          title="Patroli Jalan Raya"
          image="polisi.svg"
          navigation="/lalu-lintas/patroli-jalan-raya"
          :disabled="true"
        />
        <CardIconComponent
          title="Kecepatan Tempuh Rata-Rata"
          image="kecpt_rata2.svg"
          navigation="/lalu-lintas/kecepatan-tempuh"
          :disabled="true"
        />
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
import * as constant from "@/constants/constant";

export default {
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      this.account = JSON.parse(Cookies.get("loginResponse"));
      this.firstRowCards = [
        {
          title: "Kecelakaan",
          image: "kecelakaan.svg",
          cardSize: "100",
          navigation: "/lalu-lintas/kecelakaan",
          disabled: !this.account.scopes.includes(constant.SCOPES.accident)
        },
        {
          title: "Gangguan Aset",
          image: "gangguan_aset.svg",
          cardSize: "100",
          navigation: "/lalu-lintas/gangguan-aset",
          disabled: !this.account.scopes.includes(constant.SCOPES.asset_disruption)
        },
        {
          title: "Laporan Kejadian Khusus",
          image: "kejadian_khusus.svg",
          cardSize: "100",
          navigation: "/lalu-lintas/kejadian-khusus",
          disabled: !this.account.scopes.includes(constant.SCOPES.special_incident)
        },
        {
          title: "Gangguan Lalu Lintas",
          image: "traffic-light.svg",
          cardSize: "100",
          navigation: "/lalu-lintas/gangguan-lalu-lintas",
          disabled: !this.account.scopes.includes(constant.SCOPES.traffic_disturbance)
        },
        {
          title: "Kendaraan Derek",
          image: "derek.svg",
          cardSize: "100",
          navigation: "/lalu-lintas/derek",
          disabled: true
        },
      ]
    },
    async updateDate(dateFrom, dateTo) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
    },
  },
  data() {
    return {
      crumbs: [
        {
          text: "Layanan Lalu Lintas",
          disabled: true,
          href: "/lalu-lintas",
        },
      ],
      snackbar: false,
      message: "",
      timeout: 3000,
      dateFrom: null,
      dateTo: null,
      firstRowCards: [],
      secondRowCards: [],
      account: {},
    };
  },
};
</script>
