<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="4">
        <card-photo-group-component :images="data?.image_urls" />
        <v-card class="mt-5 px-8 py-8 rounded-xl">
          <template v-for="[key, value] of Object.entries(fields_1)">
            <template v-if="value === 'Lokasi Kejadian'">
              <h5 class="mb-3" :key="key">Lokasi Kejadian</h5>
              <div
                style="display: flex; flex-direction: row"
                class="justify-space-between mb-3"
                :key="key + 'v'"
              >
                <div style="border-left: 1px #abd1c6 solid" class="px-3">
                  <p style="font-size: 12px; color: #6c757d" class="mb-2">KM</p>
                  <p class="body-2">
                    {{
                      data?.["incident_kilometer"]
                        ? data["incident_kilometer"]
                        : "-"
                    }}
                  </p>
                </div>
                <div style="border-left: 1px #abd1c6 solid" class="body-2 px-3">
                  <p
                    style="font-size: 12px; color: #6c757d"
                    class="body-2 mb-2"
                  >
                    Jalur
                  </p>
                  <p class="body-2">
                    {{ data?.["track"] ? data["track"] : "-" }}
                  </p>
                </div>
                <div style="border-left: 1px #abd1c6 solid" class="px-3">
                  <p
                    style="font-size: 12px; color: #6c757d"
                    class="body-2 mb-2"
                  >
                    Lajur
                  </p>
                  <p class="body-2">
                    {{ data?.["lane"] ? data["lane"] : "-" }}
                  </p>
                </div>
              </div>
            </template>
            <template v-else-if="value === 'Pencapaian'">
              <h5 :key="key">Pencapaian</h5>
              <p class="body-2 mt-1" :key="key + 'v'">
                {{ data?.end_kilometer - data?.start_kilometer }}
              </p>
            </template>
            <template v-else>
              <h5 :key="key">{{ value }}</h5>
              <p class="body-2 mt-1" :key="key + 'v'">
                {{ data?.[key] || "-" }}
              </p>
            </template>
          </template>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card
          class="px-8 py-8 rounded-xl"
          height="100%"
          style="background-color: #abd1c6"
        >
          <title-component
            title="Laporan Kecelakaan yang Berhubungan"
            class="mb-5"
          />
          <kecelakaan-mini-card
            :id="data?.accident_id"
            :waktu-kejadian="data?.incident_time?.slice(0, 10)"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";
import KecelakaanMiniCard from "@/components/KecelakaanMiniCard";

export default {
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CardPhotoGroupComponent,
    KecelakaanMiniCard,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const response = await this.$axios.get(`/lj01/${this.id}`);
        this.data = response.data?.data;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(`/lj01/${this.id}`);
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/patroli-jalan-tol");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    async download() {
      try {
        const response = await this.$axios.get(`/download-lj01/${this.id}`);
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data?.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Patroli Jalan Tol_LJ01_${this.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    formatDate(date) {
      return date ? date?.slice(-8) : "-";
    },
  },
  computed: {
    title() {
      return `Laporan Hasil Tugas - LJ.01 (${this.id})`;
    },
    crumbs() {
      return [
        {
          text: "Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Patroli Jalan Tol",
          disabled: false,
          href: "/lalu-lintas/patroli-jalan-tol",
        },
        {
          text: "Per Laporan",
          disabled: false,
          href: "/lalu-lintas/patroli-jalan-tol",
        },
        {
          text: this.title,
          disabled: true,
          href: `/lalu-lintas/patroli-jalan-tol/laporan/${this.id}`,
        },
      ];
    },
  },
  data() {
    return {
      data: {},
      id: this.$route.params.id,
      snackbar: false,
      message: "",
      timeout: 3000,
      fields_1: {
        incident_time: "Waktu Kejadian",
        shift: "Shift",
        start_kilometer: "KM Awal",
        end_kilometer: "KM Akhir",
        achievement: "Pencapaian",
        location: "Lokasi Kejadian",
        incident_description: "Uraian Kejadian",
        handling_measures: "Uraian Tindak Lanjut",
      },
    };
  },
};
</script>

<style></style>
