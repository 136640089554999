"use strict";

import Vue from "vue";
import axios from "axios";
import Cookies from "js-cookie";
import * as constant from "../constants/constant";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] = "application/json";

let config = {
  baseURL: constant.BASE_URL,
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (Cookies.get("loginResponse") == undefined) {
      window.location.replace("/login");
    } else {
      const account = JSON.parse(Cookies.get("loginResponse"));
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = account.session;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data.status === "Session Anda tidak ditemukan") {
      Cookies.remove("loginResponse");
      window.location.replace("/login");
    }
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
