<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center mb-7">
      <div>
        <title-component :title="title" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn
          class="text-capitalize"
          outlined
          color="red"
          @click="deleteLaporan"
          >Hapus Laporan</v-btn
        >
        <v-btn
          class="white--text text-capitalize ml-2"
          color="#004643"
          @click="download"
          >Download</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col cols="6" height="100%">
        <card-photo-group-component
          :images="computedTrafficdisruption.image_urls"
        />
        <v-card class="mt-5 px-8 py-8 rounded-xl">
          <h5>Sumber Laporan</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.report_source }}
          </p>
          <h5>Waktu Kejadian</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.incident_time }}
          </p>
          <h5>Shift</h5>
          <p class="body-2 mt-1">{{ computedTrafficdisruption.shift }}</p>
          <h5>Lokasi Kejadian</h5>
          <p class="body-2 mt-1">{{ computedTrafficdisruption.location }}</p>
          <h5>Uraian Kejadian / Penyebab</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.incident_description }}
          </p>
          <h5>Uraian Penanganan</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.handling_description }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="6" height="100%">
        <v-card class="px-8 py-8 rounded-xl"
          ><h5>Golongan Kendaraan</h5>
          <p class="body-2 mt-1">
            Golongan {{ computedTrafficdisruption.vehicle_class_id }}
          </p>
          <h5>Jenis Kendaraan</h5>
          <p class="body-2 mt-1">{{ trafficdisruption.vehicle_type }}</p>
          <h5>Nomor Polisi</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.police_number }}
          </p>
        </v-card>
        <v-card class="px-8 py-8 rounded-xl mt-5">
          <h5>Patroli/Petugas Layanan Lalu Lintas</h5>
          <p class="body-2 mt-1">{{ computedTrafficdisruption.officer }}</p>
          <h5>Lokasi Awal Petugas</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.officer_location }}
          </p>
          <h5>Waktu Informasi Diterima</h5>
          <p class="body-2 mt-1">
            {{
              computedTrafficdisruption.information_accepted_time.slice(-8) ||
              "-"
            }}
          </p>
          <h5>Waktu Tiba di Lokasi</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.arrival_time.slice(-8) || "-" }}
          </p>
          <h5>Response Time</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.response_time.slice(-8) || "-" }}
          </p>
          <h5>Waktu Selesai</h5>
          <p class="body-2 mt-1">
            {{ computedTrafficdisruption.completion_time.slice(-8) || "-" }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent";
import CardPhotoGroupComponent from "@/components/CardPhotoGroupComponent";

export default {
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      const response = await this.$axios.get(
        `/traffic-disturbances/${this?.$route?.params.id}`
      );
      this.trafficdisruption = response.data.data;
    },
    async deleteLaporan() {
      try {
        await this.$axios.delete(
          `/traffic-disturbances/${this?.$route?.params?.id}`
        );
        this.message = "Berhasil menghapus data";
        this.snackbar = true;
        this.$router.push("/lalu-lintas/gangguan-lalu-lintas");
      } catch (error) {
        this.message = "Gagal menghapus data";
        this.snackbar = true;
        console.error(error);
      }
    },
    async download() {
      try {
        const response = await this.$axios.get(
          `/download-traffic-disturbance/${this?.$route?.params?.id}`
        );
        const mediaType = "data:application/pdf;base64,";
        const base64Data = response.data.pdfData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");

        const fileName = `Detail Kejadian ${this?.$route?.params?.id}.pdf`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  components: {
    TitleComponent,
    BreadcrumbsComponent,
    CardPhotoGroupComponent,
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    idData: "",
    dataResponse: null,
    images: [],
    model: 0,
    trafficdisruption: {},
  }),
  computed: {
    computedTrafficdisruption() {
      return {
        ...this.trafficdisruption,
        location: `KM ${this.trafficdisruption?.kilometer ?? 0} - ${
          this.trafficdisruption?.track
        } - ${this.trafficdisruption?.lane}`,
        officer_location: `KM ${
          this.trafficdisruption?.officer_kilometer ?? 0
        } - ${this.trafficdisruption?.officer_track} - ${
          this.trafficdisruption?.officer_lane
        }`,
        vehicle: `${this.trafficdisruption?.vehicle_type} - Gol. ${this.trafficdisruption?.vehicle_class_id} - ${this.trafficdisruption?.police_number}`,
      };
    },
    title() {
      return `Detail Gangguan Lalu Lintas (${this?.$route?.params?.id})`;
    },
    crumbs() {
      return [
        {
          text: "Layanan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas",
        },
        {
          text: "Gangguan Lalu Lintas",
          disabled: false,
          href: "/lalu-lintas/gangguan-lalu-lintas",
        },
        {
          text: `Detail Gangguan Lalu Lintas (${this?.$route?.params?.id})`,
          disabled: true,
          href: `/lalu-lintas/gangguan-lalu-lintas/${this?.$route?.params.id}`,
        },
      ];
    },
  },
};
</script>

<style></style>
