<template>
  <v-col>
    <h4 class="header-table-detail px-3 py-2">
      {{ title }}
    </h4>
    <div
      style="
        box-shadow: 0px 2px 10px rgba(232, 228, 230, 0.7);
        border-radius: 5px;
      "
      class="px-3 py-2"
    >
      <div
        class="mb-2 data-group-card"
        v-for="detail in detailData"
        :key="detail"
      >
        <h5 class="detail-label">{{ detail.labelName }}</h5>
        <p class="detail-val body-2 mt-1">{{ detail.value }}</p>
      </div>
      <div v-if="image !== undefined">
        <h6 class="text-center">Foto Identitas</h6>
        <div class="mx-auto p-2" style="width: 100px; height: 100px">
          <v-img class="mx-auto mt-2" style="border-radius: 20px" width="75%" height="75%" :src="image" />
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: ["title", "detailData", "image"],
};
</script>

<style>
.header-table-detail {
  color: #004643;
  background: #abd1c6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.detail-label {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 0 !important;
}

.detail-val {
  word-wrap: break-word;
  margin-bottom: 0 !important;
}

.data-group-card {
  display: flex;
  flex-direction: column;
}
</style>
