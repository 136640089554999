var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-5",staticStyle:{"max-width":"90vw"}},[_c('title-component',{attrs:{"title":_vm.title}}),_c('breadcrumbs-component',{attrs:{"crumbs":_vm.crumbs}}),_c('date-filter-component',{attrs:{"date-from":_vm.date1,"date-to":_vm.date2,"func":_vm.recallData}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"my-2 px-3 rounded-xl",staticStyle:{"box-shadow":"0px 2px 10px 0px #e8e4e67d"}},[_c('v-layout',{staticClass:"pa-5",attrs:{"row":"","wrap":"","justify-space-between":"","align-center":""}},[_c('h2',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1rem","font-weight":"700"}},[_vm._v(" Inspeksi dan Temuan "),_c('span',{staticStyle:{"font-size":"1.5rem","color":"#004643"}},[_vm._v(" "+_vm._s(_vm.percentage)+"% ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
                { label: 'Harian', type: 'day' },
                { label: 'Mingguan', type: 'week' },
                { label: 'Bulanan', type: 'month' },
              ],"item-text":"label","item-value":"type","dense":"","solo":"","height":"10","hide-details":""},on:{"input":_vm.recallData},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{ref:"realtimeChart",attrs:{"type":"line","height":"350","options":_vm.chartOptions,"series":_vm.series}})],1)],1)]),_c('v-col',[_c('div',{staticClass:"my-2 px-3 rounded-xl",staticStyle:{"box-shadow":"0px 2px 10px 0px #e8e4e67d"}},[_c('v-layout',{staticClass:"pa-5",attrs:{"row":"","wrap":"","justify-space-between":"","align-center":""}},[_c('h2',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1rem","font-weight":"700"}},[_vm._v(" Insiden dan Aset Rusak "),_c('span',{staticStyle:{"font-size":"1.5rem","color":"#e16162"}},[_vm._v(" "+_vm._s(_vm.percentage2)+"% ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
                { label: 'Harian', type: 'day' },
                { label: 'Mingguan', type: 'week' },
                { label: 'Bulanan', type: 'month' },
              ],"item-text":"label","item-value":"type","dense":"","solo":"","height":"10","hide-details":""},on:{"input":_vm.recallData},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('div',{attrs:{"id":"chart2"}},[_c('apexchart',{ref:"realtimeChart2",attrs:{"type":"line","height":"350","options":_vm.chartOptions2,"series":_vm.series2}})],1)],1)])],1),_c('v-tabs',{on:{"change":_vm.fetchData},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',{attrs:{"href":"#inisiasi"}},[_vm._v("Inisiasi")]),_c('v-tab-item',{key:1,attrs:{"value":"inisiasi","transition":false}},[_c('table-component',{attrs:{"tableTitle":"Laporan Inisiasi","headers":_vm.tableHeaders,"items":_vm.computedInitialReports,"downloadFunc":_vm.downloadInisiasi}})],1),_c('v-tab',{attrs:{"href":"#tindak-lanjut"}},[_vm._v("Tindak Lanjut")]),_c('v-tab-item',{key:2,attrs:{"value":"tindak-lanjut","transition":false}},[_c('table-component',{attrs:{"tableTitle":"Laporan Tindak Lanjut","headers":_vm.tableHeaders,"items":_vm.computedFollowUpReports,"downloadFunc":_vm.downloadTindakLanjut}})],1),_c('v-tab',{attrs:{"href":"#final"}},[_vm._v("Final")]),_c('v-tab-item',{key:3,attrs:{"value":"final","transition":false}},[_c('table-component',{attrs:{"tableTitle":"Laporan Final","headers":_vm.tableHeadersFinal,"items":_vm.computedFinalReports,"downloadFunc":_vm.downloadFinal}})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"shaped":"","top":"","color":"white"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"#004643","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }