<template>
  <div class="px-8 py-8 align-center justify-center card-container">
    <div>
      <h1 style="font-size: 18px; font-weight: 700; text-align: center">
        Foto
      </h1>
    </div>
    <v-carousel class="mt-3" height="220" style="border-radius: 15px">
      <v-carousel-item
        v-for="(image, idx) in images"
        :key="idx"
        :src="image"
        @click="openDialog(image)"
      ></v-carousel-item>
    </v-carousel>
    <v-dialog v-model="dialog" max-width="80vw">
      <v-card>
        <v-img :src="imageUrl" max-height="80vh" contain />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["images"],
  methods: {
    openDialog(image) {
      this.imageUrl = image;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style>
.card-container {
  box-shadow: 0px 2px 6px #e8e4e6;
  border-radius: 20px;
  height: fit-content;
}
</style>
