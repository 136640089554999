<template>
  <div style="display: flex">
    <img :src="require('../assets/icons/' + image)" style="height: 32px; width: auto" class="mr-2">
    <h1 style="font-size: 1.5rem; font-weight: 700">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    image: String
  },
};
</script>

<style></style>
