export function formatRupiah(angka) {
    if (angka == null) {
        angka = 0
    }

    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(angka)
}