<template>
  <v-container class="py-5" style="max-width: 90vw">
    <div style="display: flex" class="justify-space-between align-center">
      <div>
        <title-icon-component title="Tanah" image="tanah.svg" />
        <breadcrumbs-component :crumbs="crumbs" />
      </div>
      <div>
        <v-btn class="text-capitalize" href="/aset/tanah/riwayat"
          >Lihat Riwayat</v-btn
        >
<!--        <v-btn-->
<!--          @click="download"-->
<!--          class="white&#45;&#45;text text-capitalize ml-2"-->
<!--          color="#004643"-->
<!--          >Download</v-btn-->
<!--        >-->
      </div>
    </div>

    <table-component
      class="mt-5"
      :headers="tableHeaders"
      :items="computedAssets"
      :enable-download="true"
      file-name="aset_tanah.xlsx"
    />
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import TitleIconComponent from "@/components/TitleIconComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
export default {
  components: {
    TableComponent,
    TitleIconComponent,
    BreadcrumbsComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      this.$axios
        .get("/all-assets?category=1")
        .then((response) => {
          console.log((this.assets = response.data.data.assets));
          this.assignHeaders();
        });
    },
    assignHeaders() {
      const headers = [
        {
          text: "ID System",
          value: "id",
        },
        {
          text: "Kode Aset",
          value: "asset_code",
        },
        {
          text: "Lokasi Aset",
          align: "start",
          value: "asset_location",
        },
        { text: "Luas", value: "area" },
        { text: "Jenis Investasi", value: "investation_type" },
        { text: "Pemilik", value: "asset_owner" },
        { text: "Pengembang", value: "asset_developer" },
        { text: "Operator", value: "asset_operator" },
        { text: "Keterangan Tambahan", value: "information" },
        { text: "Harga Perolehan", value: "price_of_acquisition" },
        { text: "Tanggal Perolehan", value: "date_of_acquisition" },
      ];

      if (this.assets.length > 0) {
        this.assets[0]['additional_properties'].forEach((item) => {
          headers.push({text: item.name, value: item.name});
        });
      }

      headers.push({ text: "Foto", value: "foto", sortable: false, filterable: false },)

      headers.push({
        text: "",
        value: "arrow",
        sortable: false,
        filterable: false,
        width: "50px",
        align: "center",
      });
      this.tableHeaders = headers;
    },
    async download() {
      const queryParams = {
        category: "1",
      };

      try {
        const response = await this.$axios.get("/download-assets-by-category", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        a.href = url;
        a.download = `Aset Tanah.xlsx`;
        await a.click();
        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  computed: {
    computedAssets() {
      const computedAssets = []

      this.assets.forEach((asset) => {
        const computedAsset = {
          ...asset,
          href: "/aset/tanah/" + asset.id,
        }

        asset['additional_properties'].forEach((item) => {
          computedAsset[item.name] = item.value;
        })

        computedAsset['foto'] = asset.image_urls[0] || '',
        computedAssets.push(computedAsset);
      });
      return computedAssets;
    },
  },
  data: () => ({
    snackbar: false,
    message: "",
    timeout: 3000,
    assets: [],
    crumbs: [
      {
        text: "Layanan Aset",
        disabled: false,
        href: "/aset",
      },
      {
        text: "Tanah",
        disabled: true,
        href: "/aset/tanah",
      },
    ],
    tableHeaders: [],
  }),
};
</script>

<style></style>
