<template>
  <div class="card-container px-8 py-8">
    <div v-if="reporter !== undefined || reporter != null">
      <h6>Pelapor</h6>
      <p>{{ reporter }}</p>
    </div>
    <div v-if="followUpReporter !== undefined || followUpReporter != null">
      <h6>Petugas Tindak Lanjut</h6>
      <p>{{ followUpReporter }}</p>
    </div>
    <div v-if="finalReporter !== undefined || finalReporter != null">
      <h6>Petugas Finalisasi</h6>
      <p>{{ finalReporter }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reporter", "followUpReporter", "finalReporter"],
};
</script>

<style scoped>
.card-container {
  background: #abd1c6;
  min-height: 320px;
  height: 100%;
  background-image: url("@/assets/officer_2.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 60%;
}
h6 {
  font-size: 14px;
}

p {
  font-size: 20px;
  font-weight: 500;
  word-wrap: break-word;
}
</style>
