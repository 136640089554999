<template>
  <v-col>
    <p class="score-label">{{scoreLabel}}</p>
    <div class="score-card">{{scoreValue}}</div>
  </v-col>
</template>

<script>
export default {
  props: ["scoreLabel", "scoreValue"]
}
</script>

<style>
.score-label {
  font-size: 12px;
  margin: 0;
}

.score-card {
  box-shadow: 0px 2px 6px #E8E4E6;
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  font-size: 14px;
}
</style>